import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { UserDataProvider } from "@hooks/useUserData.tsx";

createRoot(document.getElementById('root')!).render(
  <UserDataProvider>
    <App />
  </UserDataProvider>
)
