import { FC } from 'react';
import css from './index.module.css';
import { Link } from 'react-router-dom';

interface Banner {
    title?: string, 
    background?: string, 
    backgroundButton?: string,
    image: string,
    link: string,
    color?: string
}

export const Banner: FC<Banner> = ({ title, background, backgroundButton, image, link, color }) => {
    return (
        <div className={css.mainBanner} style={{ background: background }}>
            <div className={css.mainBannerContent}>
                <h2 style={{ color: color }}>{title}</h2>
                <Link to={link} style={{ background: backgroundButton }}>Купить сейчас</Link>
            </div>

            <img src={image} alt={title} />
        </div>
    );
};


export const ImgBanner: FC<Banner> = ({ image, link }) => {
    return (
        <Link to={link} className={css.fullBanner}>
            <img src={image} />
        </Link>
    );
};