import { useEffect, useState, FC } from 'react'
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import Popup from '@components/popup';
import SEO from '@components/seo';

export default function Products() {
  const navigate = useNavigate();

  const [addProduct, setAddProduct] = useState(false);
  const [products, setProducts] = useState<Types.Product[]>([]);
  const [categories, setCategories] = useState<Types.Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number>(-1);
  const [hiddenVisible, setHiddenVisible] = useState<number>(0);
  const [productSearch, setProductSearch] = useState<string>('');

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/products`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setProducts(response.data);
    }).catch(error => {
      console.log(error);
    });

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const filterProducts = (products: Types.Product[], selectedCategory: number, productSearch: string, hiddenVisible: number) => {
    return products.filter(product => {
      const matchesCategory = selectedCategory === -1 || (typeof product.category === 'object' && product.category.id === selectedCategory);
      const matchesSearch = product.name.toLowerCase().includes(productSearch.toLowerCase());
      const matchesHidden = hiddenVisible === 1 ? true : product.hidden !== 1;

      console.log(product);
      return matchesCategory && matchesSearch && matchesHidden;
    });
  }

  const filteredProducts = filterProducts(products, selectedCategory, productSearch, hiddenVisible);

  return (
    <Content>
      <SEO 
        title={'Управление продуктами'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <CreatePopup showPopup={addProduct} setShowPopup={setAddProduct} />
      <h1 className={css.head}>Список товаров <i className="fi fi-rr-add" style={{ float: 'right' }} onClick={() => setAddProduct(true)} /></h1>

      <div className={css.filter}>
        <input type='text' placeholder='Поиск' onChange={(e) => setProductSearch(e.target.value)} />
        <select value={selectedCategory} onChange={(e) => setSelectedCategory(Number(e.target.value))}>
          <option value={-1}>Все категории</option>
          {categories.map(item => (
            <option value={item.id}>{item.name}</option>
          ))}
        </select>

        <select value={hiddenVisible} onChange={(e) => setHiddenVisible(Number(e.target.value))}>
          <option value={0}>Не показывать скрытые</option>
          <option value={1}>Показывать скрытые</option>
        </select>
      </div>

      <div className={css.table}>
        <div className={css.tableHeader}>
          <a>Категория</a>
          <a>Название</a>
          <a>Бренд</a>
          <a>Цена</a>
        </div>

        <div className={css.tableBody}>
          {filteredProducts.map((item) => (
            <div className={css.tableDiv} onClick={() => navigate(`/manage/product/${item.id}`)}>
              <a>{item.hidden === 1 && <i className="fi fi-rr-eye-crossed" style={{ position: 'relative', top: '2px', marginRight: 10 }}></i>} {typeof item.category === 'object' && 'name' in item.category ? item.category.name : "Неизвестно"}</a>
              <a>{item.name}</a>
              <a>{item.brand}</a>
              <a>{item.price} р.</a>

            </div>
          ))}
        </div>
      </div>
    </Content>
  );
}

interface PopupInfo {
  showPopup: boolean, 
  setShowPopup: any
}

const CreatePopup: FC<PopupInfo> = ({ showPopup, setShowPopup }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Types.Category[]>();

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/products`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(response => {
      navigate(`/manage/product/${response.data.id}`)
    })
    .catch(error => {
      console.log(error);
    });
  };
  
  return (
  <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
      <h2>Добавить продукт</h2>

      <form onSubmit={handleSubmit}>
        <div className={css.inputGrid}>
          <label>Категория</label>
          <select name='category'>
            {categories?.map(item => (
                <option value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
              
        <div className={css.inputGrid}>
          <label>Название</label>
          <input name='name' type='text' placeholder='Название' />
        </div>

        <div className={css.inputGrid}>
          <label>Бренд</label>
          <input name='brand' type='text' placeholder='Бренд' />
        </div>

        <div className={css.inputGrid}>
          <label>Цена</label>
          <input name='price' type='number' placeholder='Цена' />
        </div>

        <button className={css.saveBtn} type='submit'>Добавить новый продукт</button>
      </form>
  </Popup>
  )
}