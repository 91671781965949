import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import ColorTabs from '@components/colorTabs';
import Tabs from '@components/tabs';
import { toast } from 'sonner';
import SEO from '@components/seo';
import { Editor } from "@tinymce/tinymce-react";

export default function Product() {
  const { id } = useParams();
  const [categories, setCategories] = useState<Types.Category[]>();
  const [product, setProduct] = useState<Types.Product>();
  const [newColor, setNewColor] = useState<string>('');
  const [newSize, setNewSize] = useState<string>('');
  const [desc, setDesc] = useState<string>('');
  const [fullDesc, setFullDesc] = useState<string>('');

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ADM_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      setProduct((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          images: [...prev.images, imageUrl]
        };
      });

      toast.success('Файл загружен успешно');
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  };

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/product/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setProduct(response.data);
      setDesc(response.data.desc);
    }).catch(error => {
      console.log(error);
    });

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleImageReorder = (event: any, fromIndex: number, toIndex: number) => {
    event.preventDefault();
    const newImages = [...product!.images];
    const [movedImage] = newImages.splice(fromIndex, 1);
    newImages.splice(toIndex, 0, movedImage);
  
    setProduct((prev) => {
      if (!prev) return prev;
  
      return {
        ...prev,
        images: newImages,
      };
    });
  };

  const handleImageDelete = (event: any, index: number) => {
    event.preventDefault();

    setProduct((prev) => {
      if (!prev) return prev;
  
      const updatedImages = [...prev.images];
      updatedImages.splice(index, 1);
  
      return {
        ...prev,
        images: updatedImages,
      };
    });
  };

  const addColor = (e: any) => {
    e.preventDefault();
    const color = newColor;
    if (!color) return;

    setProduct((prev) => {
      if (!prev) return prev;

      const newId = prev.colors.length > 0 ? Math.max(...prev.colors.map((item: { id: number }) => item.id)) + 1 : 1;
      const colorNew = { id: newId, color };

      return {
        ...prev,
        colors: [...prev.colors, colorNew]
      };
    });
  }

  const addSize = (e: any) => {
    e.preventDefault();
    const size = newSize;
    if (!size) return;

    setProduct((prev) => {
      if (!prev) return prev;

      const newId = prev.sizes.length > 0 ? Math.max(...prev.sizes.map((item: { id: number }) => item.id)) + 1 : 1;
      const sizeNew = { id: newId, name: size };

      return {
        ...prev,
        sizes: [...prev.sizes, sizeNew]
      };
    });
  }

  const removeColor = (id: number) => {
    setProduct((prev) => {
      if (!prev) return prev;
      const updatedColors = prev.colors.filter((color) => color.id !== id);
      const reorderedColors = updatedColors.map((color, index) => ({
        ...color,
        id: index + 1,
      }));
  
      return {
        ...prev,
        colors: reorderedColors,
      };
    });
  };

  const removeSize = (id: number) => {
    setProduct((prev) => {
      if (!prev) return prev;
      const updatedSizes = prev.sizes.filter((color) => color.id !== id);
      const reorderedSizes = updatedSizes.map((color, index) => ({
        ...color,
        id: index + 1,
      }));
  
      return {
        ...prev,
        sizes: reorderedSizes,
      };
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
  
    setProduct((prev) => {
      if (!prev) return prev;
  
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setProduct((prev) => {
      if (!prev) return prev;

      return { ...prev, desc: desc, fullDesc: fullDesc };
    });
  }, [desc, fullDesc]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/product/${id}`, product, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    .then(_ => {
      toast.success('Информация обновлена')
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка');
    });
  };
  
  return (
    <Content>
      <SEO 
        title={'Управление продуктом'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>{product?.name}</h1>

      <form className={css.form} onSubmit={handleSubmit}>
        <div className={css.col1}>
            <div className={css.inputGrid}>
              <label>Название товара</label>
              <input name='name' type='text' placeholder='Название категории' value={product?.name} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Описание</label>
              <Editor
                tinymceScriptSrc='/assets/tinymce/js/tinymce.min.js'
                value={desc || ''}
                init={{
                  xss_sanitization: true,
                  placeholder: 'Описание',
                  branding: false,
                  promotion: false,
                  statusbar: false,
                  menubar: false,
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap'
                }}
                onEditorChange={setDesc}
              />
            </div>

            <div className={css.inputGrid}>
              <label>Полное описание</label>
              <Editor
                tinymceScriptSrc='/assets/tinymce/js/tinymce.min.js'
                value={fullDesc || ''}
                init={{
                  xss_sanitization: true,
                  placeholder: 'Подробное описание',
                  branding: false,
                  promotion: false,
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap'
                }}
                onEditorChange={setFullDesc}
              />
            </div>

            <div className={css.inputGrid}>
              <label>Категория</label>
              <select name='category' onChange={handleChange}>
                {categories?.map(item => (
                    <option value={item.id} selected={product?.category === item.id}>{item.name}</option>
                ))}
              </select>
            </div>

            <div className={css.inputGrid}>
              <label>Артикул</label>
              <input name='article' type='text' placeholder='Артикул' value={product?.article} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Теги {!product?.tags && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</label>
              <input name='tags' type='text' placeholder='Теги' value={product?.tags} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>ID из 1С {!product?.numenclature && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</label>
              <input name='numenclature' type='text' placeholder='Номенклатура' value={product?.numenclature} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Цена {!product?.price || product.price === 0 && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</label>
              <input name='price' type='number' placeholder='Цена' value={product?.price} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Скрытый продукт {product?.hidden === 1 && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</label>
              <select name='hidden' value={product?.hidden} onChange={handleChange}>
                <option value={1}>Да</option>
                <option value={0}>Нет</option>
              </select>
            </div>

            <div className={css.inputGrid}>
              <label>Новинка</label>
              <select name='isNew' value={product?.isNew} onChange={handleChange}>
                <option value={1}>Да</option>
                <option value={0}>Нет</option>
              </select>
            </div>

            <div className={css.inputGrid}>
              <label>Цвета</label>
              <div style={{ flex: 3.1 }}>
                <div className={css.flexAdd}>
                  <input type='color' onChange={(e) => setNewColor(e.target.value)} placeholder='Введите HEX код цвета' />
                  <button onClick={(e) => addColor(e)}>Добавить цвет</button>
                </div>
                
                {product?.colors && product.colors.length > 0 &&
                <>
                  <ColorTabs 
                    sections={product.colors}
                    setActive={(id: number) => removeColor(id)}
                  />
                  <a style={{ color: "#616161", fontSize: 14 }}>нажмите, чтобы удалить</a>
                </>
                }
              </div>
            </div>

            <div className={css.inputGrid}>
              <label>Размеры</label>
              <div style={{ flex: 3.1 }}>
                <div className={css.flexAdd}>
                  <input type='text' onChange={(e) => setNewSize(e.target.value)} placeholder='Введите название размера'/>
                  <button onClick={(e) => addSize(e)}>Добавить размер</button>
                </div>
                
                {product?.sizes && product.sizes.length > 0 &&
                <div className={css.sizes}>
                  <Tabs 
                    sections={product.sizes}
                    setActive={(id: number) => removeSize(id)}
                  />
                  <a style={{ color: "#616161", fontSize: 14 }}>нажмите, чтобы удалить</a>
                </div>
                }
              </div>
            </div>

            <div className={css.inputGrid}>
              <label>Заметки (для внутреннего пользования)</label>
              <textarea name='adminComment' placeholder='Заметки' style={{ height: 100 }} onChange={handleChange} value={product?.adminComment} />
            </div>

            <button className={css.saveBtn} type='submit'>Сохранить изменения</button>
        </div>

        <div className={css.col2}>
          <a>Изображения {product?.images && product.images.length <= 0 && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</a>
          <div className={css.imageList}>
            {product?.images && product.images.map((image, index) => (
              <div key={index} className={css.image}>
                <img src={image} alt={`Image ${index + 1}`} />

                <div className={css.btns}>
                  <button title='Вверх' onClick={(e) => handleImageReorder(e, index, index - 1)} disabled={index === 0}>
                    <i className="fi fi-rr-angle-small-up" />
                  </button>
                  <button title='Вниз' onClick={(e) => handleImageReorder(e, index, index + 1)} disabled={index === product.images.length - 1}>
                    <i className="fi fi-rr-angle-small-down" />
                  </button>
                  <button title='Удалить' onClick={(e) => handleImageDelete(e, index)}>
                    <i className="fi fi-rr-trash" />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}>Загрузить </label>
          <input type="file" id="fileInput" name="avatar" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0]); }} } accept="image/*"/>
        </div>
      </form>
    </Content>
  );
}
