import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import Header from "../header";
import Footer from "../footer";
import { isMobile } from "react-device-detect";
import useUserData from "@hooks/useUserData";
import MobileNavigation from "./mobile";

interface ImageData {
  image: string;
  url?: string;
  imageMobile: string;
}

interface MainContent {
  children: any;
  cover: boolean;
  image?: any | ImageData[];
  width?: number;
  coverLink?: string;
  isSlider?: boolean;
  skipCover?: boolean;
}

const MainContent: FC<MainContent> = ({ children, cover, image, width, coverLink, isSlider, skipCover }) => {
  const { siteData } = useUserData();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (isSlider && Array.isArray(image)) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % image.length);
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [image, isSlider]);

  useEffect(() => {
    if (skipCover) {
      const element = document.getElementById('main-content');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [skipCover, children]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <div className={css.sweetKit}>
      {(cover && isSlider && Array.isArray(image)) && 
      <div 
        className={css.cover} 
        style={{ backgroundImage: `url(${isMobile ? image[currentIndex].imageMobile : image[currentIndex].image})` }}
        onClick={(e) => {
          const rect = e.currentTarget.getBoundingClientRect();
          const clickX = e.clientX - rect.left;
          if (clickX < rect.width / 2) {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + image.length) % image.length);
          } else {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % image.length);
          }
        }}
      >
        <div className={css.sliderImages}>
          {image.map((imgData: ImageData, index: number) => (
            <div 
              key={index} 
              className={index === currentIndex ? css.active : css.inactive}
              style={{ 
                display: index === currentIndex ? 'block' : 'none'
              }}
            >
              <div className={css.moreBtn}>
                {imgData.url && <Link to={imgData.url}>подробнее</Link>}
              </div>
            </div>
          ))}
        </div>
        <div className={css.dots}>
          {image.map((_, index: number) => (
            <span
              key={index}
              className={index === currentIndex ? css.activeDot : css.dot}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
      }

      {(cover && siteData?.site?.universalBanner == 1) &&
        <div className={`${css.universalBanner} ${isSticky && css.universalBannerSticky}`}>
          <div className={css.universalBannerContent} style={{ backgroundColor: siteData.site.universalBannerColor, backgroundImage: `url(${siteData.site.universalBannerImg})` }}>
            <a style={{ color: siteData.site.universalBannerColorText, fontSize: 18, fontWeight: 600 }}>{siteData.site.universalBannerText}</a>
            <a href={siteData.site.universalBannerLink} target="_blank" className={css.universalBannerBtn}>подробнее</a>
          </div>
        </div>
      }

      {cover && !isSlider &&
        <div className={css.cover} style={{ backgroundImage: `url(${isMobile ? image.imageMobile : image.image})` }}>
          <div className={css.moreBtn}>
            {coverLink && <Link to={coverLink}>подробнее</Link>}
          </div>
        </div>
      }

      <div className={cover ? css.sweetKit_bodyCover : css.sweetKit_body} id="main-content">
        <Header cover={cover} />
        <div className={cover ? css.sweetKit_body_content : css.sweetKit_body_contentnCover} style={{ maxWidth: width }}>
          {children}
        </div>
      </div>

      {siteData?.site?.stringBanner == 1 &&
        <div className={css.stringBanner} style={{ backgroundColor: siteData.site.stringBannerColor }}>
          <a style={{ color: siteData.site.stringBannerColorText }}>{siteData.site.stringBannerText}</a>
        </div>
      }
      
      {isMobile && <MobileNavigation />}
      <Footer />
    </div>
  );
};

export default MainContent;