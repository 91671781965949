import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import { toast } from 'sonner';
import SEO from '@components/seo';

export default function User() {
  const { id } = useParams();
  const [user, setUser] = useState<Types.User>();
  
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/user/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        setUser(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
  
    setUser((prev) => {
      if (!prev) return prev;
  
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/user/${id}`, user, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Информация обновлена')
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка');
    });
  };

  return (
    <Content>
      <SEO 
        title={'Управление пользователем'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>Пользователь: {user?.phone}</h1>
      <form onSubmit={handleSubmit}>
        <div className={css.inputGrid}>
          <label>Имя</label>
          <input name='firstName' type='text' placeholder='Имя' value={user?.firstName} onChange={handleChange} />
        </div>

        <div className={css.inputGrid}>
          <label>Фамилия</label>
          <input name='lastName' type='text' placeholder='Фамилия' value={user?.lastName} onChange={handleChange} />
        </div>

        <div className={css.inputGrid}>
          <label>Номер телефона</label>
          <input name='phone' type='text' placeholder='Номер телефона' value={user?.phone} onChange={handleChange} />
        </div>

        <div className={css.inputGrid}>
          <label>Электронная почта</label>
          <input name='email' type='text' placeholder='Электронная почта' value={user?.email} onChange={handleChange} />
        </div>

        <div className={css.inputGrid}>
          <label>Публичное имя</label>
          <input name='publicName' type='text' placeholder='Публичное имя' value={user?.publicName} onChange={handleChange} />
        </div>

        <div className={css.inputGrid}>
          <label>Уровень доступа</label>
          <select name='access' value={user?.access} onChange={handleChange}>
              <option value={1}>Администратор</option>
              <option value={0}>Пользователь</option>
          </select>
        </div>

        <div className={css.inputGrid}>
          <label>Комментарий администратора</label>
          <textarea name='adminComment' placeholder='Заметки' style={{ height: 100 }} onChange={handleChange} value={user?.adminComment} />
        </div>

        <div className={css.inputGrid}>
          <label>Дата создания аккаунта</label>
          <a style={{ flex: 3.05 }}>{user?.createdAt}</a>
        </div>

        {user?.loyality ? ( 
        <>
            <h3 style={{ marginTop: 30 }}>Синхронизировано с GetMeBack</h3>

            <div className={css.inputGrid}>
                <label>ID клиента</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.id_client}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Номер карточки</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.card_num}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Имя, фамилия</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.name}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Пол</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.gender}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Дата рождения</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.birthday}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Группа</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.group}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Баланс бонусов</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.balance} р.</a>
            </div>

            <div className={css.inputGrid}>
                <label>Блокировка</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.is_blocked === false ? "Разблокирован" : "Заблокирован"}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Дата присоединения</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.date_created}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Реферальный код</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.referralCode}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Средний чек</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.avgBill} р.</a>
            </div>

            <div className={css.inputGrid}>
                <label>Всего покупок</label>
                <a style={{ flex: 3.05 }}>{user?.loyality.totalAmount} р.</a>
            </div>

            <div className={css.inputGrid}>
                <label>Карточка</label>
                <a style={{ flex: 3.05 }} href={user?.loyality.wallet.url} target="_blank">{user?.loyality.wallet.url}</a>
            </div>

            <div className={css.inputGrid}>
                <label>Профиль в GetMeBack</label>
                <a style={{ flex: 3.05 }} href={`https://sweetcatshop.getmeback.ru/backend/user/view?id=${user?.loyality.id_client}`} target="_blank">https://sweetcatshop.getmeback.ru/backend/user/view?id={user?.loyality.id_client}</a>
            </div>
        </>
        ): <h3 style={{ marginTop: 30, marginBottom: 0 }}>Нет синхронизации с GetMeBack</h3> }
        
        <button className={css.saveBtn} type='submit'>Сохранить изменения</button>
      </form>
    </Content>
  );
}
