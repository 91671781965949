import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';
import { toast } from "sonner";

import SEO from "@components/seo";
import MainContent from "@components/content";
import Email from "@components/email";
import Tabs from "@components/tabs";
import ColorTabs from "@components/colorTabs";

import * as Types from '../../types';
import useUserData from "@hooks/useUserData";
import { isMobile } from "react-device-detect";
import ScrollProducts from "@pages/main/scrollProducts";

export default function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const token = localStorage.getItem('authToken');
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState([]);
  const [color, setColor] = useState(1);
  const [size, setSize] = useState(1);
  const [selImg, setSelImg] = useState(0);
  const { favoriteData, refreshFavData, refreshCartData } = useUserData();

  const parsedId = id ? parseInt(id, 10) : undefined;
  const isFavorite = parsedId !== undefined && favoriteData.some(fav => fav.product.id === parsedId);

  useEffect(() => {
    setSelImg(0);

    axios.get(`${import.meta.env.VITE_API_URL}/product/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setProduct(response.data);
    }).catch(error => {
      console.log(error)
    });

    if (localStorage.getItem('authToken')) {
      axios.get(`${import.meta.env.VITE_API_URL}/recommendations`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      }).then(response => {
        const filteredProducts = response.data.filter((item: Types.Product) => item.id !== parsedId);
        setProducts(filteredProducts);
      }).catch(error => {
        console.log(error)
      });
    } else {
      axios.get(`${import.meta.env.VITE_API_URL}/popular`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        const filteredProducts = response.data.filter((item: Types.Product) => item.id !== parsedId);
        setProducts(filteredProducts);
      }).catch(error => {
        console.log(error)
      });
    }
  }, [id]);

  const addToCart = () => {
    if (!token) {
      const existingCart = JSON.parse(localStorage.getItem('cart') || '[]');
      existingCart.push({
        id: Math.floor(Math.random() * 1000000),
        product: product[0],
        params: { size: size, color: color },
        count: 1
      });
      localStorage.setItem('cart', JSON.stringify(existingCart));
      toast.success('Товар добавлен в корзину');
      refreshCartData();
      return;
    }

    const data = {
      prodID: id,
      params: { size: size, color: color }
    }

    axios.post(`${import.meta.env.VITE_API_URL}/user/cart`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      toast.success('Товар добавлен в корзину');
      refreshCartData();
    }).catch(() => {
      toast.error('Возникла ошибка, не смогли добавить товар');
    });
  };

  const addToFav = () => {
    if (!token) return navigate(`/auth`);

    axios.post(`${import.meta.env.VITE_API_URL}/user/favorites`, { prodID: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      isFavorite ? toast.success('Товар удален из избранных') : toast.success('Товар добавлен в избранные');
      refreshFavData();
    }).catch(() => {
      toast.error('Возникла ошибка, не смогли добавить товар');
    });
  };

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const [current, setCurrent] = useState(2);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const prevSlide = () => {
    setCurrent((prev) => (prev === 0 ? (product[0] as any).images.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrent((prev) => (prev === (product[0] as any).images.length - 1 ? 0 : prev + 1));
  };

  return (
    <MainContent cover={false}>      
      {product.map((info: Types.Product) => (
      <>
        <ProductInfo showPopup={showPopup} setShowPopup={setShowPopup} product={info} />

        <SEO 
          title={info.name}
          description={info.desc}
          ogImage={info.images[0]}
          ogUrl={`https://sweetcatshop.ru/product/${info.id}`}
        />

        <div className={css.mainInfo}>
          {isMobile &&
          <div className={css.carousel}>
            <div 
              className={css.carouselContainer}
              onTouchStart={(e) => setTouchStart(e.touches[0].clientX)}
              onTouchMove={(e) => setTouchEnd(e.touches[0].clientX)}
              onTouchEnd={() => {
                if (touchStart - touchEnd > 50) {
                  nextSlide();
                }
                if (touchStart - touchEnd < -50) {
                  prevSlide();
                }
              }}
            >
              {info.images.map((img, index) => {
                let className = css.slide;
                let onClickHandler = null;

                if (info.images.length === 1) {
                  className += ` ${css.active}`;
                } else if (index === current) {
                  className += ` ${css.active}`;
                } else if (index === (current - 1 + info.images.length) % info.images.length) {
                  className += ` ${css.left}`;
                  onClickHandler = prevSlide;
                } else if (index === (current + 1) % info.images.length) {
                  className += ` ${css.right}`;
                  onClickHandler = nextSlide;
                }

                return (
                  <img
                    key={index}
                    src={img}
                    className={className}
                    alt={`Slide ${index}`}
                    onClick={() => onClickHandler?.()}
                    style={{ cursor: onClickHandler ? "pointer" : "default" }}
                  />
                );
              })}
            </div>
          </div>
          }

          <div className={css.imageBlock}>
            <div className={css.imagesList}>
              {info.images.map((img, index) => (
                <img src={img} alt={info.name} onClick={() => setSelImg(index)} />
              ))}
            </div>

            <img src={info.images[selImg]} alt={info.name} className={css.imageBlockImg} />
          </div>

          <div className={css.textBlock}>
            <a>{info.brand}</a>
            <h1>{info.name}</h1>

            {info.colors.length > 0 &&
              <ColorTabs 
                sections={info.colors}
                active={color}
                setActive={setColor}
              />
            }
            
            <div dangerouslySetInnerHTML={{__html: info.desc}}></div>
            {info.fullDesc && <b onClick={() => setShowPopup(true)} style={{ cursor: 'pointer' }}><i className="fi fi-rr-info" style={{ position: 'relative', top: 2, marginRight: 5 }}></i> Подробнее</b> }
            
            {info.sizes.length > 0 &&
              <div className={css.size}>
                <div>
                  <h3>размеры</h3>
                  <a>гид по размерам</a>
                </div>
  
                <Tabs 
                  sections={info.sizes}
                  active={size}
                  setActive={setSize}
                />
              </div>
            }

            <div className={css.availability}>
              {info.quantity > 0 ? <div className={css.greenCircle} /> : <div className={css.redCircle} />}
              <a>{info.quantity > 0 ? "в наличии" : "нет в наличии"}</a>
            </div>
            
            <div className={css.btns}>
              <div className={css.addToCart} onClick={addToCart}>
                <a>добавить в корзину</a>
                <a>{formatNumber(info.price)} р.</a>
              </div>

              <div className={css.addToFavorite} onClick={addToFav}>
                <a className="dev-hidden-desktop">добавить в избранное</a>
                {isFavorite ? <i className="fi fi-ss-heart"></i> : <i className="fi fi-rr-heart"></i> }
              </div>
            </div>
          </div>
        </div>

        <h2 className={css.h2}>смотрите еще</h2>
        <ScrollProducts products={products} />
      </>
      ))}
      <Email />
    </MainContent>
  )
}

const ProductInfo = ({ showPopup, setShowPopup, product } : any) => {
  return (
    <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
      <div className={css.popupBG} onClick={() => setShowPopup(false)} />
      <div className={css.popupBody}>
          <div className={css.popupContent}>
            <h2>Информация о товаре</h2>
            <p dangerouslySetInnerHTML={{__html: product.fullDesc}}></p>
          </div>
      </div>
    </div>
  )
}
