import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import { Cart, Favorite } from "../header/popups";
import useUserData from "@hooks/useUserData";
import axios from "axios";
import * as Types from '../../types';

export default function MobileNavigation() {
    const [activePopup, setActivePopup] = useState(null);

    const togglePopup = (popupName: any) => {
        setActivePopup(activePopup === popupName ? null : popupName);
    };

    return (
    <>
        <Cart showPopup={activePopup === 'cart'} setShowPopup={() => togglePopup('cart')} />
        <Favorite showPopup={activePopup === 'favorite'} setShowPopup={() => togglePopup('favorite')} />
        {activePopup === 'menu' && <MobileMenu setShowMenu={() => togglePopup('menu')} /> }

        <div className={css.mobileNavigation}>
            <div className={css.btns}>
                <div className={css.btn} onClick={() => togglePopup('favorite')}><i className="fi fi-rr-heart"></i></div>
                <div className={css.btn} style={{ fontSize: 40, paddingBottom: 10 }} onClick={() => togglePopup('menu')}><i className="fi fi-rr-menu-burger"></i></div>
                <div className={css.btn} onClick={() => togglePopup('cart')}><i className="fi fi-rr-shopping-bag"></i></div>
            </div>
        </div>
    </>
    )
}

function MobileMenu ({ setShowMenu }: { setShowMenu: any }) {
    const { userData } = useUserData();
    const [categories, setCategories] = useState<Types.Category[]>([]);

    useEffect(() => {
      axios.get(`${import.meta.env.VITE_API_URL}/categories`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        setCategories(response.data);
      }).catch(error => {
        console.log(error);
      });
    }, []);

    return (
        <div className={`${css.mobileMenu}`}>
            <div className={css.header}>
                <Link to={`/account`} className={css.user}>
                    <img src={userData.avatar} />
                    <a>{userData.firstName}</a>
                </Link>

                <i className="fi fi-rr-cross" onClick={() => setShowMenu(false)}></i>
            </div>

            <div className={css.mainCategories}>
                <MobileMenuCategory id={0} name="Учёба и творчество" items={categories} />
                <MobileMenuCategory id={1} name="Одежда и аксессуары" items={categories} />
                <MobileMenuCategory id={2} name="Еда и напитки" items={categories} />
                <MobileMenuCategory id={3} name="Подарки и праздники" items={categories} />
                <MobileMenuCategory id={4} name="Игрушки и развлечения" items={categories} />
                <MobileMenuCategory id={5} name="О нас" items={categories} />
            </div>
        </div>
    )
}

function MobileMenuCategory ({ id, name, items } : { id: number, name: string, items: any }) {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      if (isOpen && contentRef.current) {
        setHeight(contentRef.current.scrollHeight);
      } else {
        setHeight(0);
      }
    }, [isOpen]);
    return (
        <div className={css.categoryItem} onClick={() => setIsOpen(!isOpen)}>
            <span className={css.categoryName}>{name} {isOpen ? <i className="fi fi-rr-angle-small-up"></i> : <i className="fi fi-rr-angle-small-down"></i>}</span>
            <div className={css.categoryWrapper} ref={contentRef} style={{ height: `${height}px` }}>
                {items.map((item: Types.Category) => (
                    (item.isHeader === 1 && item.headerCategory === id) &&
                    <div className={css.category}>
                        <Link style={{ fontWeight: 600, fontSize: 19 }} to={`/catalog/${item.id}`}>{item.name}</Link>
                        
                        <div className={css.categoryItems}>
                            {items.map((parentItem: Types.Category) => (
                                (parentItem.isHeader === 0 && parentItem.parent === item.id) &&
                                <Link to={`/catalog/${parentItem.id}`}>{parentItem.name}</Link>
                            ))}
                        </div>
                    </div>
                ))}
                    
                {id === 5 && (
                    <>
                    <div className={css.category}>
                        <div className={css.categoryItems}>
                            <Link to={`/about`}>О компании</Link>
                            <Link to={`/about`}>Сотрудничество</Link>
                            <Link to={`/addresses`}>Магазины</Link>
                            <Link to={`/about`}>Работа</Link>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}