import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import MiniProduct from "@components/miniProduct";
import MiniCategory from "@components/miniCategory";
import Email from "@components/email";
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";
import { Banner, ImgBanner } from "@components/mainBanner";
import { ScrollProductsWithCategory } from "./scrollProducts";
import { isMobile } from "react-device-detect";

export default function RootPage() {
  const navigate = useNavigate();
  const { siteData } = useUserData();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState<Types.Product[]>([]);
  const [recommendations, setRecommendations] = useState<Types.Product[]>([]);

  const [limit, _] = useState(50);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.log(error)
    });

    axios.get(`${import.meta.env.VITE_API_URL}/products?limit=${limit}&start=${start}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (start === 0) {
        setProducts(response.data);
      } else {
        setProducts((prevProducts: any) => [...prevProducts, ...response.data]);
      }
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });

    if (localStorage.getItem('authToken')) {
      axios.get(`${import.meta.env.VITE_API_URL}/recommendations`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      }).then(response => {
        setRecommendations(response.data);
      }).catch(error => {
        console.log(error);
      });
    } else {
      axios.get(`${import.meta.env.VITE_API_URL}/popular`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        setRecommendations(response.data);
      }).catch(error => {
        console.log(error);
      });
    }
  }, [limit, start]);

  const handleScroll = useCallback((e: any) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && !loading) {
      setLoading(true);
      setStart((prevStart) => prevStart + limit);
    }
  }, [loading, limit]);

  useEffect(() => {
    const container = document.getElementById('productListContainer');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <MainContent cover={true} image={siteData.mBanners} isSlider={true}>    
      <SEO 
        title="Главная"
        description="Магазин"
        ogUrl="https://sweetcatshop.ru/"
      />

      <div className={css.productListContainer}>
        <div className={css.arrowLeft} onClick={() => {
          const container = document.getElementById('productListContainer');
          if (container) {
            container.scrollBy({ left: -250, behavior: 'smooth' });
          }
        }}>←</div>
        <div className={css.productList} id="productListContainer">
          {categories.map((category: Types.Category) => (
            category.isHeader == 1 &&
            <div onClick={() => navigate(`/catalog/${category.id}`)}>
              <MiniCategory 
                title={category.name}
                description={category.miniDesc}
                image={category.miniCoverImg}
                isCircle={true}
              />
            </div>
          ))}
        </div>
        <div className={css.arrowRight} onClick={() => {
          const container = document.getElementById('productListContainer');
          if (container) {
            container.scrollBy({ left: 260, behavior: 'smooth' });
          }
        }}>→</div>
      </div>

      <div className={css.miniBanners}>
        {siteData?.site?.main1BannerText &&
        <Banner 
          title={siteData?.site?.main1BannerText}
          image={siteData?.site?.main1BannerTextImage}
          background={siteData?.site?.main1BannerTextBg}
          backgroundButton={siteData?.site?.main1BannerTextBtnBg}
          link={siteData?.site?.main1BannerTextLink}
          color={siteData?.site?.main1BannerTextColor}
        />  
        }

        {!isMobile && siteData?.site?.main2BannerText &&
          <Banner 
            title={siteData?.site?.main2BannerText}
            image={siteData?.site?.main2BannerTextImage}
            background={siteData?.site?.main2BannerTextBg}
            backgroundButton={siteData?.site?.main2BannerTextBtnBg}
            link={siteData?.site?.main2BannerTextLink}
            color={siteData?.site?.main2BannerTextColor}
          />
        }
      </div>

      <ScrollProductsWithCategory id={1} categories={categories} products={products} siteData={siteData} />

      {isMobile && siteData?.site?.main2BannerText &&
          <Banner 
            title={siteData?.site?.main2BannerText}
            image={siteData?.site?.main2BannerTextImage}
            background={siteData?.site?.main2BannerTextBg}
            backgroundButton={siteData?.site?.main2BannerTextBtnBg}
            link={siteData?.site?.main2BannerTextLink}
            color={siteData?.site?.main2BannerTextColor}
          />
        }

      <ScrollProductsWithCategory id={2} categories={categories} products={products} siteData={siteData} />

      <ImgBanner 
        image={siteData?.site?.main3BannerTextImage}
        link={siteData?.site?.main3BannerTextLink}
      />

      <ScrollProductsWithCategory id={3} categories={categories} products={products} siteData={siteData} />

      {categories.map((category: Types.Category) => ( category.id == siteData?.site?.category3 &&
        products.filter((item: Types.Product) => item.category === category.id).length > 0 &&
          <>
            <h2 style={{ marginTop: 30 }}>{category.name}</h2>

            <div className={css.productList}>
              {products.map((product: Types.Product) => ( 
                product.category === category.id &&
                <MiniProduct 
                  id={product.id}
                  title={product.name}
                  price={product.price}
                  image={product.images[0]}
                  isNew={product.isNew}
                />
              ))}
            </div>
          </>
      ))}

      <h2 style={{ marginTop: 30 }}>Рекомендуем</h2>

      <div className={css.recommendationsList}>
        {recommendations.map((product: Types.Product) => ( 
          <MiniProduct 
            id={product.id}
            title={product.name}
            price={product.price}
            image={product.images[0]}
            isNew={product.isNew}
          />
        ))}

        {loading && <div>загружаем...</div>}
      </div>

      <Email />
    </MainContent>
  )
}