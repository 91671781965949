import { FC } from 'react';
import css from './index.module.css';

interface MiniCategory {
    title: string, 
    description: string, 
    image: string,
    isCircle?: boolean
}

const MiniCategory: FC<MiniCategory> = ({ title, description, image, isCircle }) => {
    return (
    <>
        <div className={`${css.miniCategory} ${isCircle ? css.circle : ''}`} style={{ backgroundImage: `url(${image})` }}>
            {!isCircle && 
                <>
                    <i className={`fi fi-rr-angle-circle-right ${css.arrow}`}></i>
                    <h2>{title}</h2>
                    <a>{description}</a>
                </>
            }
        </div>

        {isCircle && 
            <h2 className={css.circleTitle}>{title}</h2> 
        }
    </>
    );
};

export default MiniCategory;