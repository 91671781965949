import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import Logo from '@assets/images/logo_f.png';
import { Cart, Favorite, Categories, Search } from "./popups";
import useUserData from "@hooks/useUserData";
import { isMobile } from "react-device-detect";

export default function Header({ cover }: { cover: boolean }) {
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const [activePopup, setActivePopup] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const { userData, cartData, siteData, favoriteData } = useUserData();
    const token = localStorage.getItem('authToken');

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const togglePopup = (popupName: any) => {
        setActivePopup(activePopup === popupName ? null : popupName);
    };

    const handleSearch = () => {
        navigate(`/search/${searchQuery}`);
    };

    return (
    <>
        <Cart showPopup={activePopup === 'cart'} setShowPopup={() => togglePopup('cart')} />
        <Favorite showPopup={activePopup === 'favorite'} setShowPopup={() => togglePopup('favorite')} />
        <Categories showPopup={activePopup === 'categories'} setShowPopup={() => togglePopup('categories')} />
        <Search showPopup={activePopup === 'search'} setShowPopup={() => togglePopup('search')} />

        <header className={`${css.header} ${isSticky || activePopup === 'categories' ? css.sticky : ''}`}>
            <div className={css.body}>
                <div className={css.leftMenu}>
                    <img src={Logo} alt="Logo" className={css.logo} onClick={() => navigate('/')} />
                </div>
                
                <div className={css.menu}>
                    <div className={css.menuIcon} onClick={() => togglePopup('categories')} onMouseEnter={() => togglePopup('categories')}>
                        {activePopup === 'categories' ? <i className="fi fi-rr-circle-xmark"></i> : <i className="fi fi-rr-menu-burger"></i>}
                    </div>

                    <input type="text" placeholder="Что нужно найти?" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }} />
                </div>
            
                <div className={css.rightMenu}>
                    {userData.access >= 1 && 
                        <i className="fi fi-rr-settings" style={{ top: '-2px' }} onClick={() => navigate('/manage')}></i>
                    }

                    {token && 
                    <div className={css.cart} onClick={() => togglePopup('favorite')}>
                        <i className="fi fi-rr-heart"></i>
                        <a className={css.cartCount}>{favoriteData.length || 0}</a>   
                    </div>
                    }

                    <div className={css.cart} onClick={() => togglePopup('cart')}>
                        <i className="fi fi-rr-shopping-bag"></i>   
                        <a className={css.cartCount}>{cartData.reduce((total, item) => total + item.count, 0)}</a>   
                    </div>

                    <Link to={`/account`} className={`${!token && css.auth}`}>{token ? <img className={css.avatar} src={userData.avatar} alt="avatar" /> : `войти в аккаунт`}</Link>
                </div>
                
                <div className={css.rightMenu} style={isMobile ? { display: 'flex' } : { display: 'none' }}>
                    {userData.access >= 1 && 
                        <i className="fi fi-rr-settings" style={{ color: (isSticky || activePopup === 'categories' || !cover) ? '#000' : siteData?.site?.headerColor }} onClick={() => navigate('/manage')}></i>
                    }

                    <i className="fi fi-rr-search" style={{ color: (isSticky || activePopup === 'categories' || !cover) ? '#000' : siteData?.site?.headerColor }} onClick={() => togglePopup('search')}></i>
                </div>
            </div>
        </header>
    </>
    )
}