import React, { useEffect, useRef, useState } from 'react';
import css from './index.module.css';
import Logo from '@assets/images/logo.svg';
import axios from 'axios';
import * as Types from '../../types';

interface Popup {
  showPopup: boolean, 
  setShowPopup: any
}

const MiniChat: React.FC<Popup> = ({ showPopup, setShowPopup }) => {
  const [msgs, setMsgs] = useState<Types.Message[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimer = useRef<NodeJS.Timeout | null>(null);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/chat`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setMsgs(response.data);
    }).catch(error => {
      console.log(error)
    });
  }, []);
  
  const connect = () => {
    const ws = new WebSocket(import.meta.env.VITE_API_WEBSOCKET);

    ws.onopen = () => {
      console.log('Connected to WebSocket');
      const token = localStorage.getItem('authToken');
      ws.send(JSON.stringify({ type: 0, jwt: token }));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message:', data);

      if (data.type == 0 && data.status == 'Authorized') {
        console.log('Websocket authorized')
        setIsConnected(true);
        if (reconnectTimer.current) {
          clearTimeout(reconnectTimer.current);
        }
      } else if (data.type === 1) {
        console.log('Recived message from admin')
        setMsgs((prevMsgs) => [...prevMsgs, { msgType: data?.msgType, from: data?.from, to: data?.to, msg: data?.msg, createdAt: data?.createdAt }]);
      }
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
      setIsConnected(false);
      reconnect();
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      ws.close();
    };

    wsRef.current = ws;
  };

  const reconnect = () => {
    if (reconnectTimer.current) {
      clearTimeout(reconnectTimer.current);
    }
    reconnectTimer.current = setTimeout(() => {
      console.log('Attempting to reconnect...');
      connect();
    }, 3000);
  };

  useEffect(() => {
    connect();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
      }
    };
  }, [import.meta.env.VITE_API_WEBSOCKET]);

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      wsRef.current!.send(JSON.stringify({ type: 1, msgType: 1, msg: imageUrl }));
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  };

  const sendMessage = () => {
    if (wsRef.current && isConnected) {
      wsRef.current.send(JSON.stringify({ type: 1, msg }));
      setMsg('');
    } else {
      console.warn('WebSocket is not connected. Message not sent.');
    }
  };

  return (
    <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
        <div className={css.popupBody}>
            <div className={css.popupContent}>
              <div className={css.messages}>
                <div className={css.chat}>
                  <div className={css.information}>
                    <img src={Logo} alt='avatar' />
                    <div className={css.uInfo}>
                      <b className={css.name}>Поддержка</b>
                      <b className={css.status}>{isConnected ? 'Соединение установлено' : 'Подключение...'}</b>
                    </div>
                  </div>
                  
                  <div className={css.actions}>
                    <i className="fi fi-rr-circle-xmark" onClick={() => setShowPopup(false)}></i>
                  </div>
                </div>

                <div style={{ height: '100%', overflow: 'scroll', marginTop: 20 }}>
                  <div className={css.msgList}>
                    {msgs.map(item => (
                      item?.msgType === 0 ?
                        <div className={`${item.to == 'admin' ? css.to : css.from}`}>
                          <a className={css.text}>
                            {item.msg}
                          </a>
                        </div>
                      :
                      <div className={`${css.file} ${item.to == 'admin' ? css.to : css.from}`}>
                        <a href={item.msg} className={`${css.text}`} target="_blank" rel="noopener noreferrer"><i className="fi fi-rr-picture"></i> файл</a>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={css.input}>
                  <textarea placeholder='Введите сообщение' onChange={(event) => setMsg(event.target.value)} value={msg} />

                  <div className={css.file}>
                    <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}><i className="fi fi-rr-picture" style={{ top: 2}}></i></label>
                    <input type="file" id="fileInput" name="avatar" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0]); }} } accept="image/*"/>
                  </div>

                  <div className={css.send} onClick={sendMessage}>
                    <i className="fi fi-rr-paper-plane"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  );
};

export default MiniChat;
