import { Link, useLocation } from "react-router-dom";
import css from './index.module.css';
import Logo from '@assets/images/logo.svg';
import useUserData from '@hooks/useUserData';

export function AdminNavigation() {
  const location = useLocation();
  const { userData } = useUserData();

  const isActive = (path: string) => {
    const regex = new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`);
    return regex.test(location.pathname);
  };

  return (
    <nav className={css.navbar} style={{ minWidth: 270 }}>
        <div>
            <img src={Logo} alt='logo' style={{ width: 80 }} />
            <div className={css.nv}>
                <Link to={'/manage/orders'} style={{ marginTop: 5 }} className={location.pathname === '/manage/orders' || location.pathname === '/manage' || isActive('/manage/order/:id') ? css.active : ''}>Заказы</Link>
                <Link to={'/manage/chats'} className={location.pathname === '/manage/chats' ? css.active : ''}>Чат</Link>
                <Link to={'/manage/products'} className={location.pathname === '/manage/products' || isActive('/manage/product/:id') ? css.active : ''}>Товары</Link>
                <Link to={'/manage/categories'} className={location.pathname === '/manage/categories' || isActive('/manage/categories/:id') ? css.active : ''}>Категории</Link>
                <Link to={'/manage/users'} className={location.pathname === '/manage/users' || isActive('/manage/user/:id') ? css.active : ''}>Пользователи</Link>
                <Link to={'/manage/promocodes'} className={location.pathname === '/manage/promocodes' ? css.active : ''}>Промокоды</Link>
                <Link to={'/manage/shops'} className={location.pathname === '/manage/shops' || isActive('/manage/shop/:id') ? css.active : ''}>Магазины</Link>
                <Link to={'/manage/settings'} className={location.pathname === '/manage/settings' ? css.active : ''}>Настройки</Link>
                <Link to={'/manage/settings/main'} className={location.pathname === '/manage/settings/main' ? css.active : ''}>Настройки слайдера</Link>
            </div>
        </div>

        <Link className={`${css.user} ${css.devHidden}`} to={`/cabinet`}>
            <img src="https://amu.edu.kz/upload/default-avatar.jpg" alt='avatar' />
            <div>
                <b className={css.name}>{userData.firstName} {userData.lastName}</b>
                <b className={css.access}>{userData.access === 1 && "Администратор"}</b>
            </div>
        </Link>
    </nav>
  );
}

export default function Content ({ children }: any) {
    return (
        <div className={css.sweetKit}>
            <div className={css.sweetKit_body}>
                <AdminNavigation />
                <div className={css.sweetKit_content}>
                    {children}
                </div>
            </div>
        </div>
    )
}