import { useEffect, useState } from 'react'
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import { toast } from 'sonner';
import SEO from '@components/seo';
import * as Types from '../../../types';
import Tabs from '@components/tabs';

export default function Settings() {
  const [settings, setSettings] = useState<any>();
  const [categories, setCategories] = useState<Types.Category[]>();
  const [tab, setTab] = useState<number>(0);
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/site`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        setSettings(response.data.site);
    }).catch(error => {
      console.log(error);
    });

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
  
    setSettings((prev: any) => {
      if (!prev) return prev;
  
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (event: any) => {
    const formData = new FormData(event.target);
    event.preventDefault();
    
    if (settings?.universalBannerImg) {
      formData.append('universalBannerImg', settings.universalBannerImg);
    }

    if (settings?.main1BannerTextImage) {
      formData.append('main1BannerTextImage', settings.main1BannerTextImage);
    }

    if (settings?.main2BannerTextImage) {
      formData.append('main2BannerTextImage', settings.main2BannerTextImage);
    }

    if (settings?.main3BannerTextImage) {
      formData.append('main3BannerTextImage', settings.main3BannerTextImage);
    }

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/site`, formData, {
        headers: {

          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Информация обновлена')
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка');
    });
  };

  const handleFileUpload = async (type: number, file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ADM_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      setSettings((prev: any) => {
        if (!prev) return prev;

        const newSettings = { ...prev };

        switch (type) {
          case 0:
            toast.success('Файл загружен успешно');
            newSettings.universalBannerImg = imageUrl;
            break;
          case 1:
            toast.success('Файл загружен успешно');
            newSettings.main1BannerTextImage = imageUrl;
            break;
          case 2:
            toast.success('Файл загружен успешно');
            newSettings.main2BannerTextImage = imageUrl;
            break;
          case 3:
            toast.success('Файл загружен успешно');
            newSettings.main3BannerTextImage = imageUrl;
            break;
          default:
            toast.error('Файл не был загружен');
            return prev;
        }

        return newSettings;
      });
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  };
  return (
    <Content>
      <SEO 
        title={'Управление настройками'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head} style={{ marginBottom: 20 }}>Настройки сайта</h1>

      <Tabs
        sections={
          [
            {
              id: 0,
              name: 'Основная информация'
            },
            {
              id: 1,
              name: 'Настройки главной страницы'
            },
            {
              id: 2,
              name: 'Настройки баннеров'
            },
            {
              id: 3,
              name: 'Настройки доставки'
            },
            {
              id: 4,
              name: 'Настройка плавающих баннеров'
            },
          ]
        }
        active={tab}
        setActive={setTab}
      />
            
      <form className={css.form} onSubmit={handleSubmit}>
        <div className={css.col1}>
          <div style={tab === 0 ? { display: 'block' } : { display: 'none' }}>
            <div className={css.inputGrid}>
              <label>Ссылка на условия соглашения</label>
              <input name='terms' placeholder='Ссылка на условия соглашения' onChange={handleChange} value={settings?.terms} />
            </div>

            <div className={css.inputGrid}>
              <label>Ссылка на политику обработки данных</label>
              <input name='privacy' placeholder='Ссылка на политику обработки данных' onChange={handleChange} value={settings?.privacy} />
            </div>

            <div className={css.inputGrid}>
              <label>Номер телефона поддержки</label>
              <input name='phone' placeholder='Номер телефона поддержки' onChange={handleChange} value={settings?.phone} />
            </div>

            <div className={css.inputGrid}>
              <label>Почта поддержки</label>
              <input name='email' placeholder='Почта поддержки' onChange={handleChange} value={settings?.email} />
            </div>

            <div className={css.inputGrid}>
              <label>Цвет в Header</label>
              <input name='headerColor' placeholder='Цвет Header' type='color' onChange={handleChange} value={settings?.headerColor} />
            </div>
          </div>

          <div style={tab === 1 ? { display: 'block' } : { display: 'none' }}>
            <div className={css.inputGrid}>
              <label>Категория №1</label>
              <select name='category1' value={settings?.category1} onChange={handleChange}>
                <option value={-1} selected={settings?.category1 === -1}>Не выбрано</option>
                {categories?.map(item => (
                  <option value={item.id} selected={settings?.category1 === item.id}>{item.name}</option>
                ))}
              </select>
            </div>

            <div className={css.inputGrid}>
              <label>Категория №2</label>
              <select name='category2' value={settings?.category2} onChange={handleChange}>
                <option value={-1} selected={settings?.category2 === -1}>Не выбрано</option>
                {categories?.map(item => (
                  <option value={item.id} selected={settings?.category2 === item.id}>{item.name}</option>
                ))}
              </select>
            </div>
        
            <div className={css.inputGrid}>
              <label>Категория №3</label>
              <select name='category3' value={settings?.category3} onChange={handleChange}>
                <option value={-1} selected={settings?.category3 === -1}>Не выбрано</option>
                {categories?.map(item => (
                  <option value={item.id} selected={settings?.category3 === item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
        
          <div style={tab === 2 ? { display: 'block' } : { display: 'none' }}>
            <div style={{ display: 'flex', gap: 20 }}>
              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Баннер №1: текст</label>
                  <input name='main1BannerText' placeholder='Тест баннера' onChange={handleChange} value={settings?.main1BannerText} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №1: текст кнопки</label>
                  <input name='main1BannerTextBtn' placeholder='Текст кнопки' onChange={handleChange} value={settings?.main1BannerTextBtn} />
                </div>
                
                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №1: ссылка</label>
                  <input name='main1BannerTextLink' placeholder='Ссылка' onChange={handleChange} value={settings?.main1BannerTextLink} />
                </div>
              </div>

              <div style={{ display: 'flex', gap: 20 }}>
                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №1: фон</label>
                  <input name='main1BannerTextBg' placeholder='Фон' type='color' onChange={handleChange} value={settings?.main1BannerTextBg} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №1: фон кнопки</label>
                  <input name='main1BannerTextBtnBg' placeholder='Фон кнопки' type='color' onChange={handleChange} value={settings?.main1BannerTextBtnBg} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №1: цвет текста</label>
                  <input name='main1BannerTextColor' placeholder='Цвет текста' type='color' onChange={handleChange} value={settings?.main1BannerTextColor} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №1: изображение</label>

                  <label htmlFor="fileInput1" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)', flex: 1 }}>Загрузить</label>
                  <input type="file" id="fileInput1" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(1, e.target.files[0]); }} } accept="image/*"/>

                  {settings?.main1BannerTextImage && <a className={css.saveBtn} style={{ flex: 1 }} href={settings?.main1BannerTextImage} target="_blank">Просмотреть картинку</a> }
                </div>
              </div>

              { /* Баннер №2 */ }
              <h2 style={{ marginTop: 20, fontSize: 19, marginBottom: 0 }}>Настройки баннера №2</h2>

              <div style={{ display: 'flex', gap: 20 }}>
                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №2: текст</label>
                  <input name='main2BannerText' placeholder='Текст' onChange={handleChange} value={settings?.main2BannerText} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №2: текст кнопки</label>
                  <input name='main2BannerTextBtn' placeholder='Текст кнопки' onChange={handleChange} value={settings?.main2BannerTextBtn} />
                </div>
                
                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №2: ссылка</label>
                  <input name='main2BannerTextLink' placeholder='Ссылка' onChange={handleChange} value={settings?.main2BannerTextLink} />
                </div>
              </div>

              <div style={{ display: 'flex', gap: 20 }}>
                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №2: фон</label>
                  <input name='main2BannerTextBg' placeholder='Фон' type='color' onChange={handleChange} value={settings?.main2BannerTextBg} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №2: фон кнопки</label>
                  <input name='main2BannerTextBtnBg' placeholder='Фон кнопки' type='color' onChange={handleChange} value={settings?.main2BannerTextBtnBg} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №2: цвет текста</label>
                  <input name='main2BannerTextColor' placeholder='Цвет текста' type='color' onChange={handleChange} value={settings?.main2BannerTextColor} />
                </div>

                <div className={css.inputGrid} style={{ flex: 1 }}>
                  <label>Баннер №2: изображение</label>

                  <label htmlFor="fileInput2" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)', flex: 1 }}>Загрузить</label>
                  <input type="file" id="fileInput2" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(2, e.target.files[0]); }} } accept="image/*"/>

                  {settings?.main2BannerTextImage && <a className={css.saveBtn} style={{ flex: 1 }} href={settings?.main2BannerTextImage} target="_blank">Просмотреть картинку</a> }
                </div>
              </div>

              { /* Баннер №3 */ }
              <h2 style={{ marginTop: 20, fontSize: 19, marginBottom: 0 }}>Настройки баннера №3</h2>

              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Баннер №3: ссылка</label>
                <input name='main3BannerTextLink' placeholder='Фон' onChange={handleChange} value={settings?.main3BannerTextLink} />
              </div>

              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Баннер №3: изображение</label>
              
                <label htmlFor="fileInput3" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)', flex: 1 }}>Загрузить</label>
                <input type="file" id="fileInput3" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(3, e.target.files[0]); }} } accept="image/*"/>
                {settings?.main3BannerTextImage && <a className={css.saveBtn} style={{ flex: 1 }} href={settings?.main3BannerTextImage} target="_blank">Просмотреть картинку</a> }
              </div>
          </div>

          <div style={tab === 3 ? { display: 'block' } : { display: 'none' }}>
            <h2 style={{ marginTop: 20 }}>Настройки доставки</h2>

            <div className={css.inputGrid}>
              <label>CDEK</label>
              <select name='cdek' value={settings?.cdek} onChange={handleChange}>
                <option value={0} selected={settings?.cdek === 0}>Включено</option>
                <option value={1} selected={settings?.cdek === 1}>Выключено</option>
              </select>
            </div>

            <div className={css.inputGrid}>
              <label>Яндекс</label>
              <select name='yandex' value={settings?.yandex} onChange={handleChange}>
                <option value={0} selected={settings?.yandex === 0}>Включено</option>
                <option value={1} selected={settings?.yandex === 1}>Выключено</option>
              </select>
            </div>

            <div className={css.inputGrid}>
              <label>Почта России</label>
              <select name='post' value={settings?.post} onChange={handleChange}>
                <option value={0} selected={settings?.post === 0}>Включено</option>
                <option value={1} selected={settings?.post === 1}>Выключено</option>
              </select>
            </div>

            <div className={css.inputGrid}>
              <label>Boxberry</label>
              <select name='boxberry' value={settings?.boxberry} onChange={handleChange}>
                <option value={0} selected={settings?.boxberry === 0}>Включено</option>
                <option value={1} selected={settings?.boxberry === 1}>Выключено</option>
              </select>
            </div>
          </div>

          <div style={tab === 4 ? { display: 'block' } : { display: 'none' }}>
            <h2 style={{ marginTop: 20 }}>Настройки баннеров</h2>
            <h2 style={{ marginTop: 20, fontSize: 19 }}>Настройки строки-баннера</h2>

            <div className={css.inputGrid}>
              <label>Строка-баннер (статус)</label>
              <select name='stringBanner' value={settings?.stringBanner} onChange={handleChange}>
                <option value={0} selected={settings?.stringBanner === 0}>Выключено</option>
                <option value={1} selected={settings?.stringBanner === 1}>Включено</option>
              </select>
            </div>

            <div style={{ display: 'flex', gap: 20 }}>
              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Текст строки-баннера</label>
                <input name='stringBannerText' placeholder='Текст строки-баннера' onChange={handleChange} value={settings?.stringBannerText} />
              </div>

              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Цвет строки-баннера</label>
                <input name='stringBannerColor' placeholder='Цвет строки-баннера' type='color' onChange={handleChange} value={settings?.stringBannerColor} />
              </div>

              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Цвет текста строки-баннера</label>
                <input name='stringBannerColorText' placeholder='Цвет текста строки-баннера' type='color' onChange={handleChange} value={settings?.stringBannerColorText} />
              </div>
            </div>

            <h2 style={{ marginTop: 20, fontSize: 19 }}>Настройки универсального баннера</h2>

            <div className={css.inputGrid}>
              <label>Универсальный баннер (статус)</label>
              <select name='universalBanner' value={settings?.universalBanner} onChange={handleChange}>
                <option value={0} selected={settings?.universalBanner === 0}>Выключено</option>
                <option value={1} selected={settings?.universalBanner === 1}>Включено</option>
              </select>
            </div>

            <div style={{ display: 'flex', gap: 20 }}>
              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Текст баннера</label>
                <input name='universalBannerText' placeholder='Текст баннера' onChange={handleChange} value={settings?.universalBannerText} />
              </div>

              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Цвет баннера</label>
                <input name='universalBannerColor' placeholder='Цвет баннера' type='color' onChange={handleChange} value={settings?.universalBannerColor} />
              </div>

              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Цвет текста баннера</label>
                <input name='universalBannerColorText' placeholder='Цвет текста баннера' type='color' onChange={handleChange} value={settings?.universalBannerColorText} />
              </div>
            </div>

            <div style={{ display: 'flex', gap: 20 }}>
              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Ссылка баннера</label>
                <input name='universalBannerLink' placeholder='Ссылка баннера' onChange={handleChange} value={settings?.universalBannerLink} />
              </div>

              <div className={css.inputGrid} style={{ flex: 1 }}>
                <label>Картинка</label>
                <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)', flex: 1 }}>Загрузить</label>
                <input type="file" id="fileInput" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(0, e.target.files[0]); }} } accept="image/*"/>

                {settings?.universalBannerImg && <a className={css.saveBtn} style={{ flex: 1 }} href={settings?.universalBannerImg} target="_blank">Просмотреть картинку</a> }
              </div>
            </div>
          </div>

          <button className={css.saveBtn} type='submit' style={{ marginTop: 10 }}>Сохранить изменения</button>
        </div>
      </form>
    </Content>
  );
}
