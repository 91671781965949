import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import { Toaster } from 'sonner';

import RootPage from '@pages/main';
import Catalog from '@pages/catalog';
import Authorization from '@pages/auth';
import Product from '@pages/product';
import Checkout from '@pages/checkout';
import Account from '@pages/account';
import Order from '@pages/account/order';
import useUserData from '@hooks/useUserData';
import Search from '@pages/search';

import Orders from '@pages/admin/orders';
import Products from '@pages/admin/products';
import Promocodes from '@pages/admin/promocodes';
import Chats from '@pages/admin/chats';
import Categories from '@pages/admin/categories';
import Category from '@pages/admin/categories/category';
import ProductAdmin from '@pages/admin/products/product';
import Users from '@pages/admin/users';
import User from '@pages/admin/users/user';
import OrderAdmin from '@pages/admin/orders/order';
import Settings from '@pages/admin/settings';
import MainBanners from '@pages/admin/settings/main';
import NotFound from '@pages/system/notFound';
import About from '@pages/about';
import Addresses from '@pages/about/addresses';
import Shops from '@pages/admin/addresses';
import ShopAdmin from '@pages/admin/addresses/shop';

function App() {
  const { userData } = useUserData();
  const token = localStorage.getItem('authToken');

  return (
    <Router basename="/">
      <Toaster position="top-right" style={{ top: 100 }} richColors expand={false} />
      <ScrollToTop />
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<RootPage />} />
        <Route path='/catalog/:id' element={<Catalog />} />
        <Route path='/search/:query' element={<Search />} />
        <Route path='/auth' element={<Authorization />} />
        <Route path='/product/:id' element={<Product />} />
        <Route path='/checkout' element={token ? <Checkout /> : <Authorization />} />
        <Route path='/account' element={token ? <Account /> : <Authorization />} />
        <Route path='/order/:id' element={token ? <Order /> : <Authorization />} />
        <Route path='/about' element={<About />} />
        <Route path='/addresses' element={<Addresses />} />

        {userData.access > 0 &&
          <>
            <Route path='/manage' element={<Orders />} />
            <Route path='/manage/orders' element={<Orders />} />
            <Route path='/manage/order/:id' element={<OrderAdmin />} />
            <Route path='/manage/products' element={<Products />} />
            <Route path='/manage/product/:id' element={<ProductAdmin />} />
            <Route path='/manage/promocodes' element={<Promocodes />} />
            <Route path='/manage/users' element={<Users />} />
            <Route path='/manage/user/:id' element={<User />} />
            <Route path='/manage/categories' element={<Categories />} />
            <Route path='/manage/categories/:id' element={<Category />} />
            <Route path='/manage/chats' element={<Chats />} />
            <Route path='/manage/settings' element={<Settings />} />
            <Route path='/manage/settings/main' element={<MainBanners />} />

            <Route path='/manage/shops' element={<Shops />} />
            <Route path='/manage/shop/:id' element={<ShopAdmin />} />
          </>
        }
      </Routes>
    </Router>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;