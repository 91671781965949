import { Link } from "react-router-dom";
import css from './index.module.css';

import Logo from '@assets/images/logo_f.png';
import useUserData from "@hooks/useUserData";
import { useEffect, useRef, useState } from "react";

export default function Footer() {
    const { siteData } = useUserData();

    return (
        <footer>
            <div className={css.left}>
                <img className={css.logo} src={Logo} alt="Sweet Cat Shop" />

                <div className={css.social}>
                    <a href="https://vk.com/sweetcatshop"><i className="fi fi-brands-vk"></i></a>
                    <a href="https://t.me/sweetcat_shop"><i className="fi fi-brands-telegram"></i></a>
                    <a href="https://www.tiktok.com/@sweetcatshop_iv"><i className="fi fi-brands-tik-tok"></i></a>
                </div>

                <a>2025 © Sweet Cat Shop</a>
            </div>

            <div className={css.right}>
                <div className={css.links}>
                    <h3>компания</h3>   
                    <Link to={`/about`}>о нас</Link>
                    <Link to={`/about`}>сотрудничество</Link>
                    <Link to={`/addresses`}>магазины сети</Link>
                    <Link to={`/about`}>обратная связь</Link>
                </div>

                <div className={css.links}>
                    <h3>продукция</h3>   
                    <Link to={``}>канцтовары</Link>
                    <Link to={``}>аксессуары</Link>
                    <Link to={``}>одежда</Link>
                    <Link to={``}>мягкие игрушки</Link>
                    <Link to={``}>слаймы</Link>
                    <Link to={``}>еда и напитки</Link>
                    <Link to={``}>подарочные наборы</Link>
                </div>

                <div className={css.links}>
                    <h3>магазин</h3>   
                    <Link to={``}>доставка и оплата</Link>
                    <Link to={``}>программа лояльности</Link>
                    <Link to={``}>политика безопасности</Link>
                </div>

                <div className={css.links}>
                    <h3>полезно</h3>   
                    <Link to={``}>акции</Link>
                    <Link to={``}>подарочные карты</Link>
                    <Link to={``}>размеры</Link>
                </div>

                <div className={css.contact}>
                    <a>связаться с нами</a>
                    <a className={css.phone}>{siteData?.site?.phone}</a>
                    <a className={css.email}>{siteData?.site?.email}</a>

                    <a className={css.recommend}>На информационном ресурсе применяются рекомендательные технологии (информационные технологии предоставления информации на основе сбора, систематизации и анализа сведений, относящихся к предпочтениям пользователей сети "Интернет", находящихся на территории Российской Федерации).</a>

                    <Link className={css.legalLink} to={siteData?.site?.terms}>Оферта</Link>
                    <Link className={css.legalLink} to={siteData?.site?.privacy}>Политика конфиденциальности</Link>

                    <div className={css.legal}>
                        <a>ООО “СВИТ КЭТ ШОП” </a>
                        <a>ИНН 3702199939</a>
                        <a>ОГРН 1183702010304</a>
                    </div>
                </div>
            </div>

            <div className={css.mobileRight}>
                <div className={css.mobileItems}>
                    <MobileFooterItem 
                        name="Компания"
                        items={[
                            {
                                url: '/about',
                                name: "О нас"
                            },
                            {
                                url: '/about',
                                name: "Сотрудничество"
                            },
                            {
                                url: '/addresses',
                                name: "Магазины сети"
                            },
                            {
                                url: '/about',
                                name: "Обратная связь"
                            }
                        ]}
                    />

                    <MobileFooterItem 
                        name="Продукция"
                        items={[
                            {
                                url: '',
                                name: "Канцтовары"
                            },
                            {
                                url: '',
                                name: "Аксессуары"
                            },
                            {
                                url: '',
                                name: "Одежда"
                            },
                            {
                                url: '',
                                name: "Мягкие игрушки"
                            },
                            {
                                url: '',
                                name: "Слаймы"
                            },
                            {
                                url: '',
                                name: "Еда и напитки"
                            },
                            {
                                url: '',
                                name: "Подарочные наборы"
                            }
                        ]}
                    />

                    <MobileFooterItem 
                        name="Магазин"
                        items={[
                            {
                                url: '',
                                name: "Доставка и оплата"
                            },
                            {
                                url: '',
                                name: "Программа лояльности"
                            },
                            {
                                url: '',
                                name: "Политика безопасности"
                            }
                        ]}
                    />

                    <MobileFooterItem 
                        name="Полезно"
                        items={[
                            {
                                url: '',
                                name: "Акции"
                            },
                            {
                                url: '',
                                name: "Подарочные карты"
                            },
                            {
                                url: '',
                                name: "Размеры"
                            }
                        ]}
                    />
                </div>

               <div className={css.contact}>
                    <a>связаться с нами</a>
                    <a className={css.phone}>{siteData?.site?.phone}</a>
                    <a className={css.email}>{siteData?.site?.email}</a>

                    <a className={css.recommend}>На информационном ресурсе применяются рекомендательные технологии (информационные технологии предоставления информации на основе сбора, систематизации и анализа сведений, относящихся к предпочтениям пользователей сети "Интернет", находящихся на территории Российской Федерации).</a>

                    <Link className={css.legalLink} to={siteData?.site?.terms}>Оферта</Link>
                    <Link className={css.legalLink} to={siteData?.site?.privacy}>Политика конфиденциальности</Link>

                    <div className={css.legal}>
                        <a>ООО “СВИТ КЭТ ШОП” </a>
                        <a>ИНН 3702199939</a>
                        <a>ОГРН 1183702010304</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

function MobileFooterItem ({ name, items } : { name: string, items: any }) {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isOpen && contentRef.current) {
        setHeight(contentRef.current.scrollHeight);
      } else {
        setHeight(0);
      }
    }, [isOpen]);
    return (
        <div className={css.mobileItem} onClick={() => setIsOpen(!isOpen)}>
            <span className={css.mobileItemName}>{name} {isOpen ? <i className="fi fi-rr-angle-small-up"></i> : <i className="fi fi-rr-angle-small-down"></i>}</span>
            <div className={css.mobileItemWrapper} ref={contentRef} style={{ height: `${height}px` }}>
                <div className={css.mobileItemLinks}>
                    {items.map((item: any) => (
                        <Link to={`${item.url}`}>{item.name}</Link>
                    ))}
                </div>
            </div>
        </div>
    )
}