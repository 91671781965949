import axios from 'axios';
import css from './index.module.css';
import { toast } from "sonner";
import Cat from '@assets/images/cat_email.png';

const Email = () => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_URL}/addToMailing`, formData, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(_ => {
      toast.success('Вы подписались на рассылку')
    }).catch(error => {
      console.log(error);
      toast.error('Почта уже подписана на рассылку');
    });
  };
  
  return (
    <div className={css.email}>
      <img src={Cat} alt="cat" className={css.cat} />
      <div className={css.text}>
        <h2>подпишись на рассылку</h2>
        <a>мы будем присылать только годные новости</a>
      </div>
      
      <form className={css.form} onSubmit={handleSubmit}>
        <input type="email" name="email" placeholder="напиши свою почту" required/>
        <button type="submit">подписаться</button>
      </form>
    </div>
  );
};

export default Email;