import { useState, useEffect } from "react";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import Email from "@components/email";

import { YMaps, Map, Placemark } from "react-yandex-maps";
import Logo from '@assets/images/logo.svg';
import Slider from "@components/slider";
import { isMobile } from "react-device-detect";

export default function Addresses() {
    const [addresses, setAddresses] = useState([]);
    const [coords, setCoords] = useState([56.999799, 40.973014]);
    const [mapInstance, setMapInstance] = useState<any>(null);
    const [selectedPoint, setSelectedPoint] = useState<null | any>(null);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_URL}/addresses`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setAddresses(response.data.shops);
        }).catch(error => {
            console.log(error);
        });
    }, []);

    const handleSelectPoint = (point: any) => {
        setCoords(point.coords.split(',').map(Number));
        setSelectedPoint(point);
        if (mapInstance) {
            mapInstance.setCenter(point.coords.split(',').map(Number));
            mapInstance.setZoom(18);
        }
    }

    const filteredAddresses = addresses.filter((address: any) => 
        address.city.toLowerCase().includes(searchTerm.toLowerCase()) || 
        address.address.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <MainContent cover={false} >    
            <SEO 
                title={`О компании`}
                description={`Как устроена компания`}
                ogUrl={`/about`}
            />

            <div className={css.flex}>
                <div className={css.col1} style={{ borderRadius: '20px', overflow: 'hidden' }}>
                    <YMaps>
                        <Map 
                            defaultState={{ center: coords, zoom: 13 }} 
                            width="100%" height={isMobile ? '500px' : '1000px'}
                            onClick={(e: any) => setSelectedPoint(e.get("coords"))}
                            instanceRef={setMapInstance}
                        >
                            {filteredAddresses.map((address: any) => (
                                <Placemark 
                                    key={address.id}
                                    geometry={address.coords.split(',').map(Number)} 
                                    options={{ 
                                        iconLayout: "default#image", 
                                        iconImageHref: Logo, 
                                        iconImageSize: selectedPoint?.id === address.id ? [50, 50] : [30, 30] 
                                    }} 
                                />
                            ))}
                        </Map>
                    </YMaps>
                </div>

                <div className={css.col2}>
                    <div className={css.aHeader}>
                        <h1 className={css.title} style={{ color: "#000", fontSize: 35 }}>Адреса магазинов</h1>

                        <input 
                            type="text" 
                            placeholder="Поиск по адресу" 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    <div className={css.addresses}>
                        {filteredAddresses.map((address: any) => (
                            <div className={`${css.address} ${selectedPoint?.id === address.id ? css.selected : ''}`} onClick={() => handleSelectPoint(address)}>
                                <div className={css.addressContent}>
                                    <b>{address.city} <a>{address.startTime} - {address.endTime}</a></b>
                                    <a>{address.address}</a>
                                    <a href={`tel:${address.phone}`}>{address.phone}</a>
                                </div>

                                {selectedPoint?.id === address.id && 
                                    <Slider images={address.images} interval={5000} borderRadius="0px 0px 10px 10px" />
                                }

                            </div>

                        ))}
                    </div>
                </div>
            </div>
            <Email />
        </MainContent>
    )
}