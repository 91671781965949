import css from './index.module.css';
import MiniProduct from "@components/miniProduct";
import * as Types from '../../types';

export default function ScrollProducts ({ products }: { products: Types.Product[] }) {
    return (
      <>
        <div className={css.productListContainer}>
            <div className={css.arrowLeft} onClick={() => {
            const container = document.getElementById(`productListContainer`);
            if (container) {
                container.scrollBy({ left: -250, behavior: 'smooth' });
            }
            }}>←</div>
            
            <div className={css.productList} id={`productListContainer`}>
                {products.map((product: Types.Product) => ( 
                    <MiniProduct 
                        id={product.id}
                        title={product.name}
                        price={product.price}
                        image={product.images[0]}
                        isNew={product.isNew}
                    />
                ))}
            </div>

            <div className={css.arrowRight} onClick={() => {
            const container = document.getElementById(`productListContainer`);
            if (container) {
                container.scrollBy({ left: 250, behavior: 'smooth' });
            }
            }}>→</div>
        </div>
      </>
    )
}

export function ScrollProductsWithCategory ({ id, categories, products, siteData }: { id: number, categories: Types.Category[], products: Types.Product[], siteData: any }) {
    if (!siteData?.site) {
        return null;
    }

    return (
      <>
      {categories.map((category: Types.Category) => ( 
          category.id == siteData?.site?.[`category${id}`] &&
          products.filter((item: Types.Product) => item.category === category.id).length > 0 &&
          <>
              <h2 style={{ marginTop: 30 }}>{category.name}</h2>
  
              <div className={css.productListContainer}>
                  <div className={css.arrowLeft} onClick={() => {
                  const container = document.getElementById(`productListContainer${id}`);
                  if (container) {
                      container.scrollBy({ left: -250, behavior: 'smooth' });
                  }
                  }}>←</div>
                  
                  <div className={css.productList} id={`productListContainer${id}`}>
                      {products.map((product: Types.Product) => ( 
                          product.category === category.id &&
                          <MiniProduct 
                              id={product.id}
                              title={product.name}
                              price={product.price}
                              image={product.images[0]}
                              isNew={product.isNew}
                          />
                      ))}
                  </div>
  
                  <div className={css.arrowRight} onClick={() => {
                  const container = document.getElementById(`productListContainer${id}`);
                  if (container) {
                      container.scrollBy({ left: 250, behavior: 'smooth' });
                  }
                  }}>→</div>
              </div>
          </>
      ))}
      </>
    )
}  