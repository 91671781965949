import { useEffect, useState } from 'react'
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import { toast } from 'sonner';
import SEO from '@components/seo';

interface Banner {
  image: string;
  imageMobile: string;
  url: string;
}

export default function MainBanners() {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [newBanner, setNewBanner] = useState<Banner>({ image: '', imageMobile: '', url: '' });

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/site`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        if (response.data && response.data.mBanners) {
          setBanners(response.data.mBanners);
        } else {
          console.error("Баннеры не найдены в ответе сервера");
        }
    }).catch(error => {
        console.error("Ошибка при получении баннеров:", error);
    });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('banners', JSON.stringify(banners));

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/site/banners`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Информация обновлена');
    })
    .catch(error => {
      console.error("Ошибка при обновлении информации:", error);
      toast.error('Ошибка');
    });
  };
  
  const handleImageReorder = (event: React.MouseEvent<HTMLButtonElement>, fromIndex: number, toIndex: number) => {
    event.preventDefault();
    setBanners((prev) => {
      if (!prev || fromIndex < 0 || toIndex < 0 || fromIndex >= prev.length || toIndex >= prev.length) return prev;
      const newImages = [...prev];
      const [movedImage] = newImages.splice(fromIndex, 1);
      newImages.splice(toIndex, 0, movedImage);
      return newImages;
    });
  };

  const handleImageDelete = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();
    setBanners((prev) => {
      if (!prev || index < 0 || index >= prev.length) return prev;
      const updatedImages = [...prev];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const handleFileUpload = async (file: File, type: 'image' | 'imageMobile') => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ADM_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      setNewBanner((prev) => ({
        ...prev,
        [type]: imageUrl
      }));

      toast.success('Файл загружен успешно');
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  };

  const handleAddBanner = () => {
    setBanners((prev) => [...prev, newBanner]);
    setNewBanner({ image: '', imageMobile: '', url: '' });
  };

  return (
    <Content>
      <SEO 
        title={'Управление настройками'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>Настройки сайта</h1>
      <a>Изображения</a>

      <form onSubmit={handleSubmit} style={{ display: 'flex',  gap: 20 }}>
        <div className={css.imageList}>
          {banners?.map((banner: Banner, index: number) => (
          <div key={index} className={css.image}>
              <img src={banner.image} alt={`Image ${index + 1}`} style={{ width: '100%', borderRadius: 10 }}/>

              <div className={css.btns}>
                <button title='Вверх' onClick={(e) => handleImageReorder(e, index, index - 1)} disabled={index === 0}>
                  <i className="fi fi-rr-angle-small-up" />
                </button>
                <button title='Вниз' onClick={(e) => handleImageReorder(e, index, index + 1)} disabled={index === banners.length - 1}>
                  <i className="fi fi-rr-angle-small-down" />
                </button>
                <button title='Удалить' onClick={(e) => handleImageDelete(e, index)}>
                  <i className="fi fi-rr-trash" />
                </button>
              </div>
          </div>
          ))}
        </div>

        <div className={css.loadImage}>
          <a style={{ fontSize: 18, fontWeight: 600 }}>Добавить баннер</a>
          
          <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}>Загрузить основное изображение</label>
          <input type="file" id="fileInput" name="image" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0], 'image'); }} } accept="image/*"/>

          {newBanner.image && <a className={css.saveBtn} style={{ flex: 1 }} href={newBanner.image} target="_blank">Просмотреть картинку</a> }

          <label htmlFor="fileInputMobile" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}>Загрузить мобильное изображение</label>
          <input type="file" id="fileInputMobile" name="imageMobile" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0], 'imageMobile'); }} } accept="image/*"/>

          {newBanner.imageMobile && <a className={css.saveBtn} style={{ flex: 1 }} href={newBanner.imageMobile} target="_blank">Просмотреть картинку</a> }

          <input type="text" placeholder="URL" value={newBanner.url} onChange={(e) => setNewBanner({ ...newBanner, url: e.target.value })} />
          <button type="button" onClick={handleAddBanner}>Добавить баннер</button>
          
          <a style={{ marginTop: 15 }}>Когда всё сделали, сохраните изменения!</a>
          <button className={css.saveBtn} type='submit' style={{ margin: 0 }}>Сохранить изменения</button>
        </div>
      </form>
    </Content>
  );
}
