import { FC } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";
import Popup from "@components/popup";
import { toast } from "sonner";
import { providers } from "@pages/account/order.info";

import CdekLogo from '@assets/images/cdek.png';
import BoxberryLogo from '@assets/images/boxberry.png';
import YandexLogo from '@assets/images/yandex.png';
import PostLogo from '@assets/images/post.png';
import Logo from '@assets/images/logo.svg';

interface PopupInfo {
  showPopup: boolean, 
  setShowPopup: any,
  setSelectedAddress: any
}

const Addresses: FC<PopupInfo> = ({ showPopup, setShowPopup, setSelectedAddress }) => {
  const { userData, siteData } = useUserData();

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const selectAddress = (address: Types.Address) => {
    setShowPopup(false);
    setSelectedAddress(address);
    toast.success('Адрес выбран');
  }

  const separateCents = (amount: number) => {
    return amount / 100;
  };

  return (
    <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
        <h2>выберите адрес</h2>
        
        <div className={css.addressList}>
          {userData.addresses?.map((item: Types.Address) => {
            const providerLogos: { [key: string]: string } = {
              'cdek': CdekLogo,
              'boxberry': BoxberryLogo,
              'yandex': YandexLogo,
              'post': PostLogo,
              'shop': Logo
            };

            const logoSrc = providerLogos[item.provider] || Logo;
            const isPickup = item.type === 0;
            const isShopOrYandex = item.provider === 'shop' || item.provider === 'yandex';
            const pointIdDisplay = !isShopOrYandex && item.type !== 1 ? `(${item.pointId})` : "";
            const deliveryText = isPickup
              ? (item.provider !== 'shop' 
                ? `привезем в ПВЗ ${providers[item.provider]} за ${item.provider === 'post' ? separateCents(item.deliveryPrice) : formatNumber(item.deliveryPrice)} р.`
                : "заберете в магазине")
              : `доставим курьером за ${formatNumber(item.deliveryPrice)} р.`;

            return (
            siteData?.site[item.provider] == 0 &&
              <div className={css.address} onClick={() => selectAddress(item)}>
                <img src={logoSrc} style={{ width: 35, height: 35 }} />
                <div className={css.textInfo}>
                  <b>{item.address} {pointIdDisplay}</b>
                  <a>{deliveryText}</a>
                </div>
              </div>
            );
          })}
        </div>

        <Link className={css.add} to={'/account'}>добавить новый адрес</Link>
    </Popup>
  )
}

export default Addresses;