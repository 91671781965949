import { FC } from 'react';
import { Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import * as Types from '../../types';
import { statusText, statusDesc } from '@pages/account/order.info';

const Order: FC<Types.MiniOrder> = ({ id, createdAt, status, totalPrice, products }) => {
    const navigate = useNavigate();

    function convertToDate(isoDate: any) {
        const date = new Date(isoDate);
        return new Intl.DateTimeFormat('ru-Ru', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(date);
    }

    return (
        <div className={css.order} onClick={() => navigate(`/order/${id}`)}>
            <div className={css.header}>
                <div className={css.left}>
                    <a>{convertToDate(createdAt)}</a>
                </div>
                <div className={css.right}>
                    <a>{totalPrice} р.</a>
                </div>
            </div>
            
            <div className={css.body}>
                <div className={css.left}>
                    <a className={css.status}>{statusText[status]}</a>
                    <a className={css.delivery}>{statusDesc[status]}</a>
                </div>
                <div className={css.right}>
                    {products.map(item => (
                        <Link className={css.product} to={`/product/${item.id}`}>
                            <img src={item.product.images[0]} alt='Продукт' />
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Order;