import { FC } from 'react';
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import { toast } from 'sonner';
import useUserData from '@hooks/useUserData';
import axios from 'axios';
import NewIcon from '@assets/images/new.svg';
import { isMobile } from 'react-device-detect';

interface MiniProduct {
    id: number;
    title: string;
    price: number; 
    image: string;
    isNew?: number;
}

const MiniProduct: FC<MiniProduct> = ({ id, title, price, image, isNew = 0 }) => {
    const navigate = useNavigate();
    const { favoriteData, refreshFavData } = useUserData();
    const token = localStorage.getItem('authToken');
    const isFavorite = id !== undefined && favoriteData.some(fav => fav.product.id === id);

    const goProduct = () => {
        navigate(`/product/${id}`);
    };
    
    const addToFav = () => {
        if (!token) return navigate(`/auth`);
    
        axios.post(`${import.meta.env.VITE_API_URL}/user/favorites`, { prodID: id }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }).then(() => {
            isFavorite ? toast.success('Товар удален из избранных') : toast.success('Товар добавлен в избранные');
            refreshFavData();
        }).catch(() => {
          toast.error('Возникла ошибка, не смогли добавить товар');
        });
    };

    return (
        <div className={css.miniProduct}>
            <img src={image} alt={title} className={css.productImage} onClick={goProduct} />
            <div className={css.favorite}>{isFavorite ? <i className="fi fi-ss-heart" onClick={addToFav}></i> : <i className="fi fi-rr-heart" onClick={addToFav}></i> }</div>
            
            <div className={css.textInfo} onClick={goProduct}>
                {!isMobile && <h2>{title}</h2> }
                {isMobile && <h2>{title.length > 35 ? `${title.substring(0, 32)}...` : title}</h2> }
                <a>{price} р.</a>
            </div>
            {isNew === 1 && <img className={css.new} src={NewIcon} />}
        </div>
    );
};

export default MiniProduct;