import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import css from './index.module.css';
import InputMask from 'react-input-mask';

import Logo from '@assets/images/logo.svg';
import SEO from "@components/seo";
import { toast } from "sonner";
import useUserData from "@hooks/useUserData";

export default function Authorization () {
  const [step, setStep] = useState<number>(0);
  const [phone, setPhone] = useState('');
  const { siteData } = useUserData();

  const handlePhone = (event: any) => {
    setPhone(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('phone', phone);

    axios.post(`${import.meta.env.VITE_API_URL}/user/auth`, formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(_ => {
        setStep(1);
    })
    .catch(error => {
        console.log(error);
    });
  };

  return (
    <div className={css.container}>
        <SEO 
            title="Авторизация"
            description="Авторизация в Sweet Cat Shop"
            ogUrl="https://sweetcatshop.ru/"
        />

        <div className={css.component}>
            <img className={css.logo} src={Logo} alt="Logo" />
            
            {step === 0 ?
                <form className={css.form} onSubmit={handleSubmit}>
                    <a className={css.head}>вход в личный кабинет</a>
                    <InputMask placeholder="введите номер телефона" mask="+7 (999) 999-99-99" value={phone} onChange={handlePhone} required autoFocus/>
                    <button type="submit">продолжить</button>
                    <a className={css.foot}>нажимая кнопку “продолжить”, я соглашаюсь с политикой обработки персональных данных</a>
                </form>   
            :
                <form className={css.form}>
                    <a className={css.head}>вход в личный кабинет</a>
                    <SmsCode phone={phone} />
                    <button type="submit">повторить отправку</button>
                    <a className={css.foot}>нажимая кнопку “продолжить”, я соглашаюсь с политикой обработки персональных данных</a>
                </form>
            }

            <div className={css.legal}>
                <a href={siteData?.site?.terms}>Лицензионное соглашение</a>
                <a href={siteData?.site?.privacy}>Политика конфиденциальности</a>
            </div>
        </div>

    </div>
  )
}

function SmsCode({ phone }: { phone: string }) {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [codeEntered, setCodeEntered] = useState<boolean>(false);
    const inputRefs = useRef<any>([]);

    const handleChange = (e: any, index: number) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) {
            const newCode = [...code];

            newCode[index] = value.slice(0, 1);
            setCode(newCode);

            if (index < 5 && value !== '') {
                inputRefs.current[index + 1].focus();
            }

            if (newCode.every((digit) => digit !== '')) {
                setCodeEntered(true);
            } else {
                setCodeEntered(false);
            }
        }
    };

    const handleBackspace = (e: any, index: number) => {
        if (e.keyCode === 8 && index > 0 && code[index] === '') {
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            inputRefs.current[index - 1].focus();
            setCodeEntered(false);
        }
    };

    const renderInputs = () => {
        const inputs = [];
        for (let i = 0; i < 6; i++) {
            inputs.push(
                <input
                    key={i}
                    type="text"
                    maxLength={1}
                    value={code[i]}
                    onChange={(e) => handleChange(e, i)}
                    onKeyDown={(e) => handleBackspace(e, i)}
                    ref={(el) => (inputRefs.current[i] = el)}
                    autoFocus={i === 0}
                />
            );
        }
        return inputs;
    };

    const sendCode = () => {
        const enteredCode = code.join('');
        axios.post(`${import.meta.env.VITE_API_URL}/user/auth/code`, { phone: phone, code: enteredCode })
            .then(response => {
                if (response.data.cart) {
                    const localCart = JSON.parse(localStorage.getItem('cart') || '[]');
                    const handleCartSync = async () => {
                        if (localCart.length > 0) {
                            if (window.confirm('У вас есть товары в корзине. Хотите заменить их на товары из вашего аккаунта?')) {
                                try {
                                    await axios.post(`${import.meta.env.VITE_API_URL}/user/cart/clear`, {}, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${response.data.token}`
                                        }
                                    });

                                    for (const item of localCart) {
                                        await axios.post(`${import.meta.env.VITE_API_URL}/user/cart`, 
                                            { 
                                                prodID: item.product.id,
                                                count: item.count,
                                                params: item.params
                                            }, 
                                            {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${response.data.token}`
                                                }
                                            }
                                        );
                                    }
                                } catch (error) {
                                    console.error('Ошибка синхронизации корзины:', error);
                                }
                            }
                        }
                        
                        localStorage.removeItem('cart');
                        localStorage.setItem('authToken', response.data.token);
                        window.location.replace("/account");
                    };

                    handleCartSync();
                } else {
                    localStorage.setItem('authToken', response.data.token);
                    window.location.replace("/account");
                }
            })
            .catch(() => {
                toast.error('Неверно введен код авторизации');
            });
    };

    useEffect(() => {
        if (codeEntered) {
            sendCode();
            setCodeEntered(false);
        }
    }, [codeEntered]);

    return (
        <div className={css.enterCode}>
            {renderInputs()}
        </div>
    );
}
