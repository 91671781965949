import { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';

import { toast } from 'sonner';
import SEO from '@components/seo';

export default function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shop, setShop] = useState<Types.Shop>({
    id: 0,
    address: '',
    phone: '',
    images: [],
    coords: '',
    city: '',
    startTime: '',
    endTime: '',
  });
  const [suggestions, setSuggestions] = useState<any>([]);
  const [openSuggestions, setOpenSuggestions] = useState(false);


  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ADM_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      setShop((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          images: [...prev.images, imageUrl]
        };
      });

      toast.success('Файл загружен успешно');
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      axios.get(`${import.meta.env.VITE_API_ADM_URL}/shops`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      }).then(response => {
        setShop(response.data.find((shop: Types.Shop) => shop.id === Number(id)));
      }).catch(error => {
        console.log(error);
      });
    }
  }, [id]);

  const handleImageReorder = (event: any, fromIndex: number, toIndex: number) => {
    event.preventDefault();
    const newImages = [...shop!.images];
    const [movedImage] = newImages.splice(fromIndex, 1);
    newImages.splice(toIndex, 0, movedImage);
  
    setShop((prev) => {
      if (!prev) return prev;
  
      return {
        ...prev,
        images: newImages,
      };
    });
  };

  const handleImageDelete = (event: any, index: number) => {
    event.preventDefault();

    setShop((prev) => {
      if (!prev) return prev;
  
      const updatedImages = [...prev.images];
      updatedImages.splice(index, 1);
  
      return {
        ...prev,
        images: updatedImages,
      };
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
  
    setShop((prev) => {
      if (!prev) return prev;
  
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const url = id === 'new' 
      ? `${import.meta.env.VITE_API_ADM_URL}/shop` 
      : `${import.meta.env.VITE_API_ADM_URL}/shop/${id}`;

    const method = id === 'new' ? 'post' : 'put';

    axios({
      method: method,
      url: url,
      data: shop,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    .then(_ => {
      toast.success(id === 'new' ? 'Магазин создан' : 'Информация обновлена')
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка');
    });
  };

  const handleDelete = () => {
    if (id === 'new') return;
    axios.delete(`${import.meta.env.VITE_API_ADM_URL}/shop/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(_ => {
      toast.success('Магазин удален');
      navigate('/admin/addresses');
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <Content>
      <SEO 
        title={'Управление магазином'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>{shop?.address || 'Новый магазин'}</h1>

      <form className={css.form} onSubmit={handleSubmit}>
        <div className={css.col1}>
            <div className={css.inputGrid}>
              <label>Город или название</label>
              <input name='city' type='text' placeholder='Город (название)' value={shop?.city} onChange={handleChange} />
            </div>

            <div className={css.inputGrid} >
              <label>Адрес</label>

              <div style={{ flex: 3.1 }}>
                <input 
                  type="text" 
                  placeholder="адрес" 
                  style={{ margin: '15px 0'}}
                  defaultValue={shop?.address}
                  onChange={async (e) => {
                    if (e.target.value.length > 5) {
                      try {
                        const response = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_API_YANDEXGEOCODE}&format=json&geocode=${e.target.value}`);
                        const data = await response.json();
                        const suggestions = data.response.GeoObjectCollection.featureMember.map((item: any) => ({
                          name: item.GeoObject.name,
                          coords: item.GeoObject.Point.pos.split(' ').map(Number).reverse()
                        }));
                        setSuggestions(suggestions);
                        setOpenSuggestions(true);
                      } catch (error) {
                        console.error("Ошибка при геокодировании:", error);
                      }
                    }
                  }} 
                />

                {openSuggestions && (
                  <div className={css.suggestions}>
                    {suggestions.map((item: any, index: number) => (
                      <div key={index} className={css.suggestion} onClick={() => {
                        setShop((prev) => {
                          if (!prev) return prev;

                          return {
                            ...prev,
                            address: item.name,
                            coords: item.coords.join(', '),
                          };
                        });

                        setOpenSuggestions(false);
                      }}>{item.name}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className={css.inputGrid}>
              <label>Координаты</label>
              <input name='coords' type='text' placeholder='Координаты' value={shop?.coords} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Номер телефона</label>
              <input name='phone' type='text' placeholder='Номер телефона' value={shop?.phone} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Время начала работы</label>
              <input name='startTime' type='text' placeholder='Время начала работы' value={shop?.startTime} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Время окончания работы</label>
              <input name='endTime' type='text' placeholder='Время окончания работы' value={shop?.endTime} onChange={handleChange} />
            </div>

            <button className={css.saveBtn} type='submit'>Сохранить изменения</button>
            
            {id !== 'new' && (
              <a className={css.deleteBtn} onClick={handleDelete}>Удалить магазин</a>
            )}
        </div>

        <div className={css.col2}>

          <a>Изображения {shop?.images && shop.images.length <= 0 && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</a>
          <div className={css.imageList}>
            {shop?.images && shop.images.map((image, index) => (
              <div key={index} className={css.image}>
                <img src={image} alt={`Image ${index + 1}`} />

                <div className={css.btns}>
                  <button title='Вверх' onClick={(e) => handleImageReorder(e, index, index - 1)} disabled={index === 0}>
                    <i className="fi fi-rr-angle-small-up" />
                  </button>
                  <button title='Вниз' onClick={(e) => handleImageReorder(e, index, index + 1)} disabled={index === shop.images.length - 1}>
                    <i className="fi fi-rr-angle-small-down" />
                  </button>
                  <button title='Удалить' onClick={(e) => handleImageDelete(e, index)}>
                    <i className="fi fi-rr-trash" />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}>Загрузить </label>
          <input type="file" id="fileInput" name="avatar" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0]); }} } accept="image/*"/>
        </div>
      </form>
    </Content>
  );
}
