import { useState, useEffect, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import * as Types from '../../types';
import axios from "axios";

import CartProduct from "@components/cartProduct";
import useUserData from "@hooks/useUserData";
import { isMobile } from "react-device-detect";

interface Popup {
    showPopup: boolean, 
    setShowPopup?: any,
    query?: string
}

export const Cart: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const navigate = useNavigate();
    const { cartData } = useUserData();

    const calculateTotal = () => {
        return cartData.reduce((total, item) => {
            return total + item.product.price * item.count;
        }, 0);
    };

    const formatNumber = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)} />
            <div className={css.popupBody}>
                <div className={css.popupContent}>
                    <h3 className={css.mobileName}>
                        корзина
                        <i className="fi fi-rr-cross" onClick={() => setShowPopup(false)}></i>
                    </h3>

                    <div className={css.productGrid} style={isMobile ? { maxHeight: '70vh' } : {}}>
                        {cartData.length > 0 ? cartData.map((cart: Types.CartData) => (
                            <CartProduct 
                                id={cart.id}
                                prodID={cart.product.id}
                                image={cart.product.images[0]}
                                title={cart.product.name}
                                desc={<div className={css.tinfo}>{cart.product.colors.find(i => i.id === cart.params.color) && <div className={css.color} style={{ color: cart.product.colors.find(i => i.id === cart.params.color)?.color }} /> } {cart.product.sizes.find(i => i.id === cart.params.size) && cart.product.sizes.find(i => i.id === cart.params.size)?.name}</div>}
                                price={cart.product.price}
                                count={cart.count}
                                buttons={true}
                            />
                        )) : <a>корзина пуста, добавьте сюда что-нибудь</a>}
                    </div>
                    
                    {cartData.length > 0 &&
                    <>
                        <div className={css.total}>
                            <a>итог</a>
                            <a>{formatNumber(calculateTotal())} р.</a>
                        </div>
                        
                        <div className={css.checkout} onClick={() => navigate(`/checkout`)}>оформить заказ</div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export const Favorite: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const navigate = useNavigate();
    const { favoriteData } = useUserData();
    const token = localStorage.getItem('authToken');

    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)} />
            <div className={css.popupBody}>
                <div className={css.popupContent}>
                    <h3 className={css.mobileName}>
                        избранное
                        <i className="fi fi-rr-cross" onClick={() => setShowPopup(false)}></i>
                    </h3>

                    {token ? 
                        <div className={css.productGrid}>
                            {favoriteData.length > 0 ? favoriteData.map((fav: Types.Favorites) => (
                                <CartProduct 
                                    id={fav.product.id}
                                    prodID={fav.product.id}
                                    image={fav.product.images[0]}
                                    title={fav.product.name}
                                    desc={''}
                                    price={fav.product.price}
                                />
                            )) : <a>нет ничего в избранном, добавьте сюда что-то</a>}
                        </div>
                        :
                        <>
                            <h3 style={{ margin: 0 }}>мы вас не узнали, авторизуйтесь, пожалуйста</h3>
                            <div className={css.checkout} onClick={() => navigate(`/auth`)}>войти в аккаунт</div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export const Search: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = () => {
        setShowPopup(false);
        navigate(`/search/${searchQuery}`);
    };

    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)} />
            <div className={css.popupBody}>
                <div className={css.popupContent}>
                    <div className={css.searchMobile}>
                        <input style={{ marginTop: 80 }} type="text" placeholder="что нужно найти?" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }} />
                        <div className={css.checkout} onClick={() => navigate(`/search/${searchQuery}`)}>поиск</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Categories: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const [categories, setCategories] = useState<Types.Category[]>([]);
    const [activeMainCategory, setActiveMainCategory] = useState<number>(0);

    useEffect(() => {
      axios.get(`${import.meta.env.VITE_API_URL}/categories`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        setCategories(response.data);
      }).catch(error => {
        console.log(error);
      });
    }, []);
  
    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)}/>
            <div className={css.headerCategoriesPopupBody} onMouseLeave={() => setShowPopup(false)}>
                <div className={css.headerCategoriesPopup}>
                    <div className={css.headerCategories}>
                        <div className={css.mainCategories}>
                            <h2 className={activeMainCategory === 0 ? css.active : ''} onClick={() => setActiveMainCategory(0)} onMouseEnter={() => setActiveMainCategory(0)}>Учёба и творчество</h2>
                            <h2 className={activeMainCategory === 1 ? css.active : ''} onClick={() => setActiveMainCategory(1)} onMouseEnter={() => setActiveMainCategory(1)}>Одежда и аксессуары</h2>
                            <h2 className={activeMainCategory === 2 ? css.active : ''} onClick={() => setActiveMainCategory(2)} onMouseEnter={() => setActiveMainCategory(2)}>Еда и напитки</h2>
                            <h2 className={activeMainCategory === 3 ? css.active : ''} onClick={() => setActiveMainCategory(3)} onMouseEnter={() => setActiveMainCategory(3)}>Подарки и праздники</h2>
                            <h2 className={activeMainCategory === 4 ? css.active : ''} onClick={() => setActiveMainCategory(4)} onMouseEnter={() => setActiveMainCategory(4)}>Игрушки и развлечения</h2>
                            <h2 className={activeMainCategory === 5 ? css.active : ''} onClick={() => setActiveMainCategory(4)} onMouseEnter={() => setActiveMainCategory(5)}>О нас</h2>
                        </div>

                        <div className={css.categories}>
                            {categories.map(item => (
                                (item.isHeader === 1 && item.headerCategory === activeMainCategory) &&
                                <div className={css.category}>
                                    <Link style={{ fontWeight: 600, fontSize: 19 }} to={`/catalog/${item.id}`}>{item.name}</Link>
                                    
                                    <div className={css.categoryItems}>
                                        {categories.map(parentItem => (
                                            (parentItem.isHeader === 0 && parentItem.parent === item.id) &&
                                            <Link to={`/catalog/${parentItem.id}`}>{parentItem.name}</Link>
                                        ))}
                                    </div>
                                </div>
                            ))}

                            {activeMainCategory === 5 && (
                                <>
                                <div className={css.category}>
                                    <Link style={{ fontWeight: 600, fontSize: 19 }} to={`/about`}>О компании</Link>

                                    <div className={css.categoryItems}>
                                        <Link to={`/about`}>О компании</Link>
                                        <Link to={`/about`}>Сотрудничество</Link>
                                        <Link to={`/addresses`}>Магазины</Link>
                                        <Link to={`/about`}>Работа</Link>
                                    </div>
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}