import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';

import SEO from '@components/seo';

export default function Addresses() {
  const navigate = useNavigate();
  const [shops, setShops] = useState<Types.Shop[]>([]);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/shops`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        setShops(response.data);
    }).catch(error => {
        console.log(error);
    });
  }, []);

  return (
    <Content>
      <SEO 
        title={'Управление магазинами'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>Список магазинов <Link to={`/manage/shop/new`}><i className="fi fi-rr-add" style={{ float: 'right' }} /></Link></h1>

      <div className={css.table}>
        <div className={css.tableHeader}>
          <a>Город</a>
          <a>Адрес</a>
          <a>Номер телефона</a>
        </div>

        <div className={css.tableBody}>
          {shops.map((item) => (
            <div className={css.tableDiv} onClick={() => navigate(`/manage/shop/${item.id}`)}>
              <a>{item.city}</a>
              <a>{item.address}</a>
              <a>{item.phone}</a>
            </div>
          ))}
        </div>
      </div>
    </Content>
  );
}
