import { FC } from "react";
import css from './index.module.css';
import { isMobile } from "react-device-detect";

interface Tab {
    id: number | string,
    name: string
}

interface Tabs {
  sections: Tab[],
  active?: number,
  setActive?: any,
  isFull?: boolean
}

const Tabs:FC<Tabs> = ({ sections, active, setActive, isFull }) => {
  return (
    <div className={css.sections}>
        {sections.map(section => (
            <div className={`${css.section} ${active === section.id ? css.active : ''}`} style={isFull && !isMobile ? { width: "100%", textAlign: 'center' } : {}} onClick={() => setActive(section.id)}>{section.name}</div>
        ))}
    </div>
  );
};

export default Tabs;