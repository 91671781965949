import { useState, useEffect, FC } from "react";
import css from './index.module.css';
import * as Types from '../../types';
import axios from "axios";
import { toast } from "sonner";
import Tabs from "@components/tabs";
import useUserData from "@hooks/useUserData";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import CdekLogo from '@assets/images/cdek.png';
import BoxberryLogo from '@assets/images/boxberry.png';
import YandexLogo from '@assets/images/yandex.png';
import PostLogo from '@assets/images/post.png';
import DeliveryLogo from '@assets/images/delivery.png';

interface Popup {
  addressID?: string,
  showPopup: boolean, 
  setShowPopup: any
}
export const Address: FC<Popup> = ({ showPopup, setShowPopup }) => {
  const { siteData, refreshUserData } = useUserData();

  const [deliveryFilter, setDeliveryFilter] = useState<"all" | "cdek" | "boxberry" | "yandex" | "post">('all');
  const [selectedPoint, setSelectedPoint] = useState<null | any>(null);
  const [selectedAddress, setSelectedAddress] = useState<null | any>(null);

  const [deliveryType, setDeliveryType] = useState<"pickup" | "courier" | null>("pickup");
  const [address, setAddress] = useState("Иваново");
  const [coords, setCoords] = useState([56.999799, 40.973014]);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [openSuggestions, setOpenSuggestions] = useState(false);

  const [pickupPoints, setPickupPoints] = useState<any>([]);
  const [mapInstance, setMapInstance] = useState<any>(null);

  const [courierPrice, setCourierPrice] = useState<any>({});
  const [courierProvider, setCourierProvider] = useState<any>('cdek');

  const handleBoundsChange = async () => {
    if (!mapInstance) return;
    
    const center = mapInstance.getCenter();
    try {
      const response = await fetch(
        `https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_API_YANDEXGEOCODE}&format=json&geocode=${center[1]},${center[0]}`
      );
      const data = await response.json();
      const firstGeoObject = data.response.GeoObjectCollection.featureMember[0]?.GeoObject;
      
      if (firstGeoObject) {
        const components = firstGeoObject.metaDataProperty.GeocoderMetaData.Address.Components;
        const city = components.find((comp: any) => comp.kind === 'locality');

        if (city && city.name !== address) {
          setAddress(city.name);
        }

        setCoords([center[0], center[1]]);
      }
    } catch (error) {
      console.error("Ошибка при определении города:", error);
    }
  };

  useEffect(() => {
    setDeliveryType("pickup");
    setCourierProvider("cdek");
    setCourierPrice({});
    setSelectedPoint(null);
    setSelectedAddress(null);
    setPickupPoints([]);
    setCoords([56.999799, 40.973014]);
    setAddress("Иваново");
    setOpenSuggestions(false);
  }, [showPopup])

  useEffect(() => {
    if (address && coords && deliveryType === "pickup") {
      axios.get(`${import.meta.env.VITE_API_URL}/delivery/points?&cityName=${address}&coords=${coords[0]},${coords[1]}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      }).then(response => {
        setPickupPoints(response.data.data);
      }).catch(error => {
        console.log(error);
      });
    }
  }, [address, coords, deliveryType]);

  const handleSelectPickup = (point: any) => {
    setSelectedPoint(point);
    setDeliveryType("pickup");
    
    const addressMap: { [key: string]: string } = {
      cdek: point.point.location.address_full,
      boxberry: point.point.Address,
      yandex: point.point.address.full_address,
      post: point.point['address-source']
    };

    setAddress(addressMap[point.type]);
  };

  const handleCourierDelivery = (coords: any) => {
    axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_API_YANDEXGEOCODE}&format=json&geocode=${coords[1]},${coords[0]}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      const firstGeoObject = response.data.response.GeoObjectCollection.featureMember[0]?.GeoObject;
      const components = firstGeoObject.metaDataProperty.GeocoderMetaData.Address.Components;
      const address = firstGeoObject.metaDataProperty.GeocoderMetaData.Address.formatted;
      const city = components.find((comp: any) => comp.kind === 'locality').name;
      
      axios.get(`${import.meta.env.VITE_API_URL}/delivery/courier?cityName=${city}&address=${address}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      }).then(response => {
        setCourierPrice(response.data.data.prices);
      }).catch(error => {
        console.log(error);
      });

      setSelectedPoint(coords);
      setSelectedAddress(address);
    }).catch(error => {
      console.log(error);
    });
  };

  const addAddress = (point: any = selectedPoint) => {
    const provider = deliveryType === "courier" ? courierProvider : point.type;
    const pointData = point.point;

    axios.post(`${import.meta.env.VITE_API_URL}/user/addresses`, { 
      type: deliveryType === "courier" ? 1 : 0,
      provider: provider,
      pointId: deliveryType === 'pickup' ? (provider === "yandex" ? pointData.id : provider === "boxberry" ? pointData.Code : provider === "cdek" ? pointData.code : pointData['postal-code']) : -1,
      coords: coords,
      address: deliveryType === "courier" ? selectedAddress : address
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(_ => {
      toast.success("Адрес добавлен");
      setShowPopup(false);
      refreshUserData();
    }).catch(_ => {
      toast.error("Ошибка при добавлении адреса");
    });
  };

  const handleSelectType = (type: any) => {
    setSelectedPoint(null);
    setDeliveryType(type);
  };

  const separateCents = (amount: number) => amount / 100;

  return (
      <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>

          <div className={css.popupBG} onClick={() => setShowPopup(false)} />
          <div className={css.popupBody}>
              <div className={css.popupContent} style={{ width: 1500 }}>
                <h2>добавить адрес доставки <i onClick={() => setShowPopup(false)} className="fi fi-rr-cross"></i></h2>

                <div className={css.flex}>
                  <div className={css.left}>
                    <Tabs 
                      sections={
                        [
                          {
                            id: 'pickup',
                            name: 'самовывоз'
                          },
                          {
                            id: 'courier',
                            name: 'курьером'
                          }
                        ]
                      }
                      active={deliveryType as any}
                      setActive={handleSelectType}
                      isFull={true}
                    />

                    <input 
                      type="text" 
                      placeholder="адрес" 
                      style={{ margin: '15px 0'}}
                      onChange={async (e) => {
                        if (e.target.value.length > 5) {
                          try {
                            const response = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_API_YANDEXGEOCODE}&format=json&geocode=${e.target.value}`);
                            const data = await response.json();
                            const suggestions = data.response.GeoObjectCollection.featureMember.map((item: any) => ({
                              name: item.GeoObject.name,
                              coords: item.GeoObject.Point.pos.split(' ').map(Number).reverse()
                            }));
                            setSuggestions(suggestions);
                            setOpenSuggestions(true);
                          } catch (error) {
                            console.error("Ошибка при геокодировании:", error);
                          }
                        }
                      }} 
                    />

                    {deliveryType === "pickup" && (
                    <div style={{ marginBottom: 10 }}>
                      <Tabs
                        sections={
                        [
                          { id: 'all', name: 'Все' },
                          { id: 'cdek', name: 'CDEK' },
                          { id: 'boxberry', name: 'Boxberry' },
                          { id: 'yandex', name: 'Яндекс' },
                          { id: 'post', name: 'Почта России' }
                        ]
                      }
                      active={deliveryFilter as any}
                        setActive={setDeliveryFilter}
                        isFull={false}
                      />
                    </div>
                    )}

                    {openSuggestions && (
                      <div className={css.suggestions}>
                        {suggestions.map((item: any, index: number) => (
                          <div key={index} className={css.suggestion} onClick={() => {
                            if (mapInstance) {
                              if (deliveryType === "courier") { 
                                handleCourierDelivery(item.coords);
                              }
                              
                              mapInstance.setCenter(item.coords);
                              setOpenSuggestions(false);
                            }
                          }}>{item.name}</div>
                        ))}
                      </div>
                    )}
                    
                    {deliveryType === "pickup" && !selectedPoint && (
                      <div className={css.pointsList}>
                        {(siteData?.site && siteData?.site['cdek'] == 0) && (deliveryFilter === "cdek" || deliveryFilter === 'all') && pickupPoints?.cdekPoints?.length > 0 && pickupPoints.cdekPoints.map((point: any, index: number) => (
                          <div className={css.point} key={index} onClick={() => handleSelectPickup({ type: 'cdek', point })}>
                            <div className={css.pointMain}>
                              <img src={CdekLogo} style={{ width: 35, height: 35 }} /> 
                              <div className={css.pointInfo}>
                                <a>{point.type === "POSTAMAT" ? "Постамат" : "Пункт выдачи"} CDEK ({point.code})</a> 
                                <p>{point.location.address_full}</p>
                              </div>
                            </div>

                            <b>{pickupPoints?.prices?.cdek?.delivery_sum || 0} р.</b>
                          </div>
                        ))}
                  
                        {(siteData?.site && siteData?.site['boxberry'] == 0) && (deliveryFilter === "boxberry" || deliveryFilter === 'all') && pickupPoints?.boxberryPoints?.length > 0 && pickupPoints.boxberryPoints.map((point: any, index: number) => (
                          <div className={css.point} key={index} onClick={() => handleSelectPickup({ type: 'boxberry', point })}>
                            <div className={css.pointMain}>
                              <img src={BoxberryLogo} style={{ width: 35, height: 35 }} /> 
                              <div className={css.pointInfo}>
                                <a>{point.Postamat === true ? "Постамат" : "Пункт выдачи"} Boxberry ({point.Code})</a> 
                                <p>{point.Address}</p>
                              </div>
                            </div>

                            <b>{pickupPoints?.prices?.boxberry?.TotalPrice || 0} р.</b>
                          </div>
                        ))}

                        {(siteData?.site && siteData?.site['yandex'] == 0) && (deliveryFilter === "yandex" || deliveryFilter === 'all') && pickupPoints?.yandexPoints?.length > 0 && pickupPoints.yandexPoints.map((point: any, index: number) => (
                          <div className={css.point} key={index} onClick={() => handleSelectPickup({ type: 'yandex', point })}>
                            <div className={css.pointMain}>
                              <img src={YandexLogo} style={{ width: 35, height: 35 }} /> 
                              <div className={css.pointInfo}>
                                <a>{point.name}</a> 
                                <p>{point.address.full_address}</p>
                              </div>
                            </div>

                            <b>{pickupPoints?.prices?.yandex?.pricing_total || 0}</b>
                          </div>

                        ))}

                        {(siteData?.site && siteData?.site['post'] == 0) && (deliveryFilter === "post" || deliveryFilter === 'all') && pickupPoints?.pochtaPoints?.length > 0 && pickupPoints.pochtaPoints.map((point: any, index: number) => (
                          <div className={css.point} key={index} onClick={() => handleSelectPickup({ type: 'post', point })}>
                            <div className={css.pointMain}>
                              <img src={PostLogo} style={{ width: 35, height: 35 }} /> 
                              <div className={css.pointInfo}>
                                <a>{point['type-code']} {point['settlement']} ({point['postal-code']})</a> 
                                <p>{point['address-source']}</p>
                              </div>
                            </div>

                            <b>{separateCents(pickupPoints?.prices?.pochta || 0)} р.</b>
                          </div>
                        ))}
                      </div>
                    )}

                    {deliveryType === "pickup" && selectedPoint && (
                      <div className={css.pointInfo}>
                        <a className={css.back} onClick={() => setSelectedPoint(null)}>Назад</a>
                        {selectedPoint.type === "cdek" && <h3 style={{ margin: 0, fontSize: 20, marginBottom: 5 }}>{selectedPoint.point.type === "POSTAMAT" ? "Постамат" : "Пункт выдачи"} CDEK ({selectedPoint.point.code})</h3> }
                        {selectedPoint.type === "boxberry" && <h3 style={{ margin: 0, fontSize: 20, marginBottom: 5 }}>{selectedPoint.point.Postamat === true ? "Постамат" : "Пункт выдачи"} Boxberry ({selectedPoint.point.Code})</h3> }
                        {selectedPoint.type === "yandex" && <h3 style={{ margin: 0, fontSize: 20, marginBottom: 5 }}>{selectedPoint.point.name}</h3> }
                        {selectedPoint.type === "post" && <h3 style={{ margin: 0, fontSize: 20, marginBottom: 5 }}>{selectedPoint.point['type-code']} {selectedPoint.point['settlement']} ({selectedPoint.point['postal-code']})</h3> }

                        {selectedPoint.type === "cdek" && <p style={{ margin: 0, fontSize: 15, marginBottom: 10 }}>{selectedPoint.point.location.address_full}</p> }
                        {selectedPoint.type === "boxberry" && <p style={{ margin: 0, fontSize: 15, marginBottom: 10 }}>{selectedPoint.point.Address}</p> }
                        {selectedPoint.type === "yandex" && <p style={{ margin: 0, fontSize: 15, marginBottom: 10 }}>{selectedPoint.point.address.full_address}</p> }
                        {selectedPoint.type === "post" && <p style={{ margin: 0, fontSize: 15, marginBottom: 10 }}>{selectedPoint.point['address-source']}</p> }
                       
                        <div className={css.pointAddInfo}>
                          <b>Стоимость доставки</b>
                          {selectedPoint.type === "cdek" && <a>{pickupPoints?.prices?.cdek?.delivery_sum || 0} р.</a> }
                          {selectedPoint.type === "boxberry" && <a>{pickupPoints?.prices?.boxberry?.TotalPrice || 0} р.</a> }
                          {selectedPoint.type === "yandex" && <a>{pickupPoints?.prices?.yandex?.pricing_total || 0}</a> }
                          {selectedPoint.type === "post" && <a>{separateCents(pickupPoints?.prices?.pochta || 0)} р.</a> }
                        </div>

                        <div className={css.pointAddInfo}>
                          <b>Способы оплаты</b>
                          <a>Картой на сайте</a>
                        </div>

                        {selectedPoint.type != 'post' && (
                          <div className={css.pointAddInfo}>
                            <b>Как добраться</b>
                            {selectedPoint.type === "cdek" && <a>{selectedPoint.point.note}</a> }
                            {selectedPoint.type === "boxberry" && <a>{selectedPoint.point.TripDescription}</a> }
                            {selectedPoint.type === "yandex" && <a>{selectedPoint.point.address.comment}</a> }
                          </div>
                        )}

                        <div className={css.checkout} onClick={() => addAddress(selectedPoint)}>заберу отсюда</div>
                      </div>
                    )}

                    {deliveryType === "courier" && selectedAddress && (
                      <div className={css.pointInfo}>
                        <h3 style={{ margin: 0, fontSize: 20, marginBottom: 5 }}>Доставка курьером</h3>
                        <p style={{ margin: 0, fontSize: 15, marginBottom: 10 }}>{selectedAddress}</p>

                        <div className={css.pointAddInfo}>
                          <b>Способы оплаты</b>
                          <a>Картой на сайте</a>
                        </div>

                        <div className={css.courierServices}>
                          {courierPrice?.cdek?.delivery_sum != 0 &&
                            <div className={`${css.courierService} ${courierProvider === "cdek" ? css.active : ""}`} onClick={() => setCourierProvider("cdek")}>
                              <div className={css.courierServiceMain}>
                                <img src={CdekLogo} style={{ width: 35, height: 35 }} />
                                <div className={css.courierServiceInfo}>
                                  <a>CDEK</a>
                                  <p>Доставка курьером</p>
                                </div>
                              </div>
                              <b style={{ textWrap: 'nowrap' }}>{courierPrice?.cdek?.delivery_sum || 0} р.</b>
                            </div>
                          }

                          {courierPrice?.boxberry?.DeliveryCosts[0]?.TotalPrice != 0 &&
                            <div className={`${css.courierService} ${courierProvider === "boxberry" ? css.active : ""}`} onClick={() => setCourierProvider("boxberry")}>
                              <div className={css.courierServiceMain}>
                                <img src={BoxberryLogo} style={{ width: 35, height: 35 }} />
                                <div className={css.courierServiceInfo}>
                                  <a>BoxBerry</a>
                                  <p>Доставка курьером</p>
                                </div>
                              </div>
                              <b style={{ textWrap: 'nowrap' }}>{courierPrice?.boxberry?.DeliveryCosts[0]?.TotalPrice || 0} р.</b>
                            </div>
                          }

                          {courierPrice?.yandex && Object.keys(courierPrice.yandex).length > 0 &&
                            <div className={`${css.courierService} ${courierProvider === "yandex" ? css.active : ""}`} onClick={() => setCourierProvider("yandex")}>
                              <div className={css.courierServiceMain}>
                                <img src={YandexLogo} style={{ width: 35, height: 35 }} />
                                <div className={css.courierServiceInfo}>
                                  <a>Яндекс</a>
                                  <p>Доставка курьером</p>
                                </div>
                              </div>
                              <b style={{ textWrap: 'nowrap' }}>{courierPrice?.yandex?.pricing_total || 0}</b>
                            </div>     
                          }

                          {courierPrice?.pochta != 0 &&
                            <div className={`${css.courierService} ${courierProvider === "post" ? css.active : ""}`} onClick={() => setCourierProvider("post")}>
                              <div className={css.courierServiceMain}>
                                <img src={PostLogo} style={{ width: 35, height: 35 }} />
                                <div className={css.courierServiceInfo}>
                                  <a>Почта России</a>
                                  <p>Доставка курьером</p>
                                </div>
                              </div>
                              <b style={{ textWrap: 'nowrap' }}>{separateCents(courierPrice?.pochta || 0)} р.</b>
                            </div>
                          }
                        </div>

                        <div className={css.checkout} onClick={() => addAddress(selectedPoint)}>привезите сюда</div>
                      </div>
                    )}
                  </div>
                  
                  <div className={css.right} style={{ borderRadius: '15px', overflow: 'hidden' }}>
                    <YMaps>
                      <Map 
                        defaultState={{ center: coords, zoom: 13 }} 
                        width="100%" height="600px"
                        onClick={(e: any) => deliveryType === "courier" ? handleCourierDelivery(e.get("coords")) : undefined}
                        onBoundsChange={handleBoundsChange}
                        instanceRef={setMapInstance}
                      >
                        {deliveryType === "pickup" && (
                          <>
                          {(siteData?.site && siteData?.site['cdek'] == 0) && (deliveryFilter === "cdek" || deliveryFilter === 'all') && pickupPoints?.cdekPoints?.length > 0 && pickupPoints.cdekPoints.map((point: any) => (
                            <Placemark 
                              key={point.code} 
                              geometry={[point.location.latitude, point.location.longitude]} 
                              options={{ 
                                iconLayout: "default#image", 
                                iconImageHref: CdekLogo, 
                                iconImageSize: selectedPoint?.point?.code === point.code ? [50, 50] : [30, 30] 
                              }}
                              onClick={() => handleSelectPickup({ type: 'cdek', point })}
                            />
                          ))}

                          {(siteData?.site && siteData?.site['boxberry'] == 0) && (deliveryFilter === "boxberry" || deliveryFilter === 'all') && pickupPoints?.boxberryPoints?.length > 0 && pickupPoints.boxberryPoints.map((point: any) => {
                            const gpsCoordinates = point.GPS.split(',').map(Number);
                            return (
                              <Placemark 
                                key={point.Code} 
                                geometry={gpsCoordinates} 
                                options={{ 
                                  iconLayout: "default#image", 
                                  iconImageHref: BoxberryLogo, 
                                  iconImageSize: selectedPoint?.point?.Code === point.Code ? [50, 50] : [30, 30],
                                }}
                                onClick={() => handleSelectPickup({ type: 'boxberry', point })}
                              />
                            );

                          })}

                          {(siteData?.site && siteData?.site['yandex'] == 0) && (deliveryFilter === "yandex" || deliveryFilter === 'all') && pickupPoints?.yandexPoints?.length > 0 && pickupPoints.yandexPoints.map((point: any) => {
                            return (
                              <Placemark 
                                key={point.operator_station_id} 
                                geometry={[point.position.latitude, point.position.longitude]} 
                                options={{ 
                                  iconLayout: "default#image", 
                                  iconImageHref: YandexLogo, 
                                  iconImageSize: selectedPoint?.point?.operator_station_id === point.operator_station_id ? [50, 50] : [30, 30] 
                                }}
                                onClick={() => handleSelectPickup({ type: 'yandex', point })}
                              />
                            );
                          })}

                          {(siteData?.site && siteData?.site['post'] == 0) && (deliveryFilter === "post" || deliveryFilter === 'all') && pickupPoints?.pochtaPoints?.length > 0 && pickupPoints.pochtaPoints.map((point: any) => {
                            return (
                              <Placemark 
                                key={point['postal-code']} 

                                geometry={[point.latitude, point.longitude]} 
                                options={{ 
                                  iconLayout: "default#image", 
                                  iconImageHref: PostLogo, 
                                  iconImageSize: selectedPoint?.point?.['postal-code'] === point['postal-code'] ? [50, 50] : [30, 30] 
                                }}
                                onClick={() => handleSelectPickup({ type: 'post', point })}
                              />
                            );
                          })}
                          </>
                        )}

                        {deliveryType === "courier" && selectedPoint && (
                          <Placemark 
                            geometry={selectedPoint} 
                            options={{ 
                              iconLayout: "default#image", 
                              iconImageHref: DeliveryLogo, 
                              iconImageSize: [30, 30]
                            }} 
                          />
                        )}
                      </Map>
                    </YMaps>
                  </div>
                </div>
              </div>
          </div>
      </div>
  )
}

export const AddressDelete: FC<Popup> = ({ addressID, showPopup, setShowPopup }) => {
  const { refreshUserData } = useUserData();

  const saveAddress = () => {
    axios.delete(`${import.meta.env.VITE_API_URL}/user/addresses/${addressID}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(_ => {
      toast.success('Адрес успешно удален');
      setShowPopup(false);
      refreshUserData();
    }).catch(error => {
      toast.error('Не удалось удалить адрес');
      console.log(error);
    });
  }
  
  return (
      <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
          <div className={css.popupBG} onClick={() => setShowPopup(false)} />
          <div className={css.popupBody}>
              <div className={css.popupContent}>
                <h2>удалить адрес? <i onClick={() => setShowPopup(false)} className="fi fi-rr-cross"></i></h2>
                <div className={css.checkout} onClick={() => saveAddress()}>удалить адрес</div>
              </div>
          </div>
      </div>
  )
}

export const Cards: FC<Popup> = ({ showPopup, setShowPopup }) => {
  const { userData } = useUserData();

  const addNewCard = () => {
    axios.post(`${import.meta.env.VITE_API_URL}/pay/connect/`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      const data = response.data;
      if (data.url) {
        window.location.href = data.url;
      } else {
        toast.error('Не удалось добавить карту');
      }
    }).catch(error => {
      console.log(error);
      toast.error('Не удалось добавить карту');
    });
  }
  
  return (
      <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
          <div className={css.popupBG} onClick={() => setShowPopup(false)} />
          <div className={css.popupBody}>
              <div className={css.popupContent}>
                <h2>привязанные карты <i onClick={() => setShowPopup(false)} className="fi fi-rr-cross"></i></h2>

                <div className={css.savedCards}>
                  {userData.payments?.map((item: Types.ConnCard) => (
                    <div className={css.savedCard}>
                      <a>{item.data.title}</a>
                    </div>
                  ))}
                </div>

                <div className={css.checkout} onClick={() => addNewCard()}>добавить карту</div>
              </div>
          </div>
      </div>
  )
}

export const Edit: FC<Popup> = ({ showPopup, setShowPopup }) => {
  const { userData, refreshUserData } = useUserData();
  const [newAvatar, setNewAvatar] = useState('');
 
  useEffect(() => {
    setNewAvatar(userData?.avatar);
  }, [userData]);

  const handleFileUpload = async (file: File) => {
    toast.info('Загружаем аватарку...');
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ADM_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      setNewAvatar(imageUrl);

      toast.success('Аватарка загружена успешно');
    } catch (error) {
      toast.error('Не удалось загрузить аватарку');
      console.error("Ошибка загрузки файла:", error);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('avatar', newAvatar);

    axios.post(`${import.meta.env.VITE_API_URL}/user`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Информация обновлена');
      refreshUserData();
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка авторизации');
    });
  };
  
  return (
    <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
        <div className={css.popupBG} onClick={() => setShowPopup(false)} />
        <div className={css.popupBody}>
            <div className={css.popupContent}>
              <h2>изменить информацию <i onClick={() => setShowPopup(false)} className="fi fi-rr-cross"></i></h2>

              <form onSubmit={handleSubmit}>
                <div className={css.gridForm}>
                  <label>аватарка</label>
                  
                  <div className={css.uplAvatar}>
                    {newAvatar && <img src={newAvatar} className={css.avatar} alt="avatar" /> }
                    <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#E0E0E0', color: '#000', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}>загрузить новую аватарку</label>
                    <input type="file" id="fileInput" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0]); }} } accept="image/*"/>
                  </div>
                </div>

                <div className={css.gridForm}>
                  <label>имя</label>
                  <input name="firstName" type="text" placeholder="имя" defaultValue={userData.firstName} />
                </div>

                <div className={css.gridForm}>
                  <label>фамилия</label>
                  <input name="lastName" type="text" placeholder="фамилия" defaultValue={userData.lastName} />
                </div>

                <div className={css.gridForm}>
                  <label>отображаемое имя</label>
                  <input name="publicName" type="text" placeholder="отображаемое имя" defaultValue={userData.publicName} />
                </div>

                <div className={css.gridForm}>
                  <label>электронная почта</label>
                  <input name="email" type="text" placeholder="электронная почта" defaultValue={userData.email} />
                </div>

                <button className={css.checkout} type="submit">обновить информацию</button>
              </form>
            </div>
        </div>
    </div>
  )
}