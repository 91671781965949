import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import AboutImage from '@assets/images/about.png';
import AboutImageMobile from '@assets/images/about_mobile.png';

import About1 from '@assets/images/about1.png';
import About2 from '@assets/images/about2.png';
import About3 from '@assets/images/about3.png';
import About4 from '@assets/images/about4.png';
import About5 from '@assets/images/about5.png';
import About5M from '@assets/images/about5-mobile.png';
import About6 from '@assets/images/about6.png';
import About6M from '@assets/images/about6-mobile.png';
import About7 from '@assets/images/about7.png';
import About7M from '@assets/images/about7-mobile.png';

import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

export default function About() {
    const [current, setCurrent] = useState(2);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [sliderImages] = useState([ About2, About3, About4 ]);
  
    const prevSlide = () => {
      setCurrent((prev) => (prev === 0 ? sliderImages.length - 1 : prev - 1));
    };
  
    const nextSlide = () => {
      setCurrent((prev) => (prev === sliderImages.length - 1 ? 0 : prev + 1));
    };
  
  return (
    <MainContent cover={true} image={{ image: AboutImage, imageMobile: AboutImageMobile }}>    
        <SEO 
            title={`О компании`}
            description={`Как устроена компания`}
            ogUrl={`/about`}
        /> 
        
        <div className={css.btnsMain}>
            <div className={css.btn}>
                СОТРУДНИЧЕСТВО
            </div>

            <Link className={css.btn} to={`/addresses`}>
                АДРЕСА МАГАЗИНОВ
            </Link>
        </div>

        <div className={css.Block1}>
            <div className={css.left}>
                <h3>Свит Кэт — сеть магазинов для молодёжи.</h3>
                <p>Российская компания, основанная в 2013 г., занимающаяся разработкой и производством милой канцелярии, уникальных подарков и яркой одежды. Первый магазин открылся в г.Иваново. Сейчас уже более 27 магазинов, а также бренд представлен почти на всех онлайн платформах.</p>
            </div>

            <div className={css.right}>
                <img src={About1} />
            </div>
        </div>

        <div className={css.Block2}>
            <div className={css.mission}>
                <h2>Наша миссия</h2>
                <p>Сделать жизнь клиентов ярче и радостнее, создавая вдохновляющие продукты и даря уникальный опыт! Мы верим: вдохновение и волшебство всегда рядом! Для нас важно напомнить и детям, и взрослым о том, что каждый день и каждое занятие могут быть ярче и интереснее. Мы приглашаем вас смотреть на мир иначе: видеть красоту в мелочах, наполнять свои дни фантазией, теплом и яркими эмоциями.</p>
            </div>

            <div className={css.mission}>
                <h2>Наша цель</h2>
                <p>Наша цель – качественное российское производство разных групп товаров от полиграфии до одежды, нацеленное на то, чтобы подчеркнуть стиль, уникальность и индивидуальность, создать уют и сделать жизнь каждого счастливее!</p>
            </div>
        </div>
        
        <div className={css.Block3}>
            <div className={css.carousel}>
                <div 
                className={css.carouselContainer}
                onTouchStart={(e) => setTouchStart(e.touches[0].clientX)}
                onTouchMove={(e) => setTouchEnd(e.touches[0].clientX)}
                onTouchEnd={() => {
                    if (touchStart - touchEnd > 50) {
                        nextSlide();
                    }
                    if (touchStart - touchEnd < -50) {
                        prevSlide();
                    }
                }}
                >
                {sliderImages.map((img, index) => {
                    let className = css.slide;
                    let onClickHandler = null;

                    if (index === current) {
                        className += ` ${css.active}`;
                    } else if (index === (current - 1 + sliderImages.length) % sliderImages.length) {
                        className += ` ${css.left}`;
                        onClickHandler = prevSlide;
                    } else if (index === (current + 1) % sliderImages.length) {
                        className += ` ${css.right}`;
                        onClickHandler = nextSlide;
                    }

                    return (
                    <img
                        key={index}
                        src={img}
                        className={className}
                        alt={`Slide ${index}`}
                        onClick={() => onClickHandler?.()}
                        style={{ cursor: onClickHandler ? "pointer" : "default" }}
                    />
                    );
                })}
                </div>
            </div>
          
        </div>

        <div className={css.Block4}>
            <h2>Наши ценности</h2>

            <div className={css.blocks}>
                <div className={css.block} style={{ background: "#945EC4"}}>
                    <h3 style={{ color: "#FEFFB0" }}>АТМОСФЕРА</h3>
                    <a style={{ color: "#fff" }}>вдохновения, в которую хочется возвращаться</a>
                </div>

                <div className={css.block} style={{ background: "#BBE4E6"}}>
                    <h3 style={{ color: "#945EC4" }}>КАЧЕСТВО</h3>
                    <a style={{ color: "#000" }}>продуктов и обслуживания</a>
                </div>

                <div className={css.block} style={{ background: "#FFD2EA"}}>
                    <h3 style={{ color: "#945EC4" }}>УНИКАЛЬНОСТЬ</h3>
                    <a style={{ color: "#000" }}>и индивидуальность</a>
                </div>

                <div className={css.block} style={{ background: "#FEFFB0"}}>
                    <h3 style={{ color: "#945EC4" }}>СООБЩЕСТВО</h3>
                    <a style={{ color: "#000" }}>мы люди, работающие для людей</a>
                </div>
            </div>

            <img src={isMobile ? About5M : About5} alt='Map' />
        </div>

        <div className={css.Block5}>
            {!isMobile && <img src={About6} alt='sweetcat' /> }

            <div className={css.blocks}>
                {isMobile && <img src={About6M} alt='sweetcat' /> }

                <div className={css.block}>
                    <a>Этот кот волшебный, он приносит удачу и делает наши будни счастливее. Также он основной герой нашего мерча и контента. Хитрый взгляд и жажда творчества, помогают ему стать обаятельным напарником и другом. Вместе гулять, выбирать товары, смеяться и транслировать ценности бренда.</a>
                </div>
                
                {!isMobile && 
                    <div className={css.btn}>
                        ПОЗНАКОМИТЬСЯ
                    </div>
                }
            </div>

            {isMobile && 
                <div className={css.btn}>
                    ПОЗНАКОМИТЬСЯ
                </div>
            }
        </div>
        
        <a className={css.Block6}>
            <img src={isMobile ? About7M : About7} alt='work' />
        </a>

        <div className={css.Block7}>
            <h3>ВЫ МОЖЕТЕ СТАТЬ НАШИМ ПАРТНЕРОМ:</h3>

            <div className={css.blocks}>
                <a>ЗАПРОСИТЬ ОПТОВЫЙ ПРАЙС</a>
                <a style={{ background: "#DBC7FF" }}>ЗАПРОСИТЬ ПРЕЗЕНТАЦИЮ ПО ФРАНШИЗЕ</a>
            </div>
        </div>
    </MainContent>
  )
}