import { FC, useState, useEffect } from "react";
import css from './index.module.css';

interface SliderProps {
  images: string[];
  interval: number;
  borderRadius?: string;
}

const Slider: FC<SliderProps> = ({ images, interval, borderRadius }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  return (
    <div className={css.slider}>
      <div className={css.sliderImages}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            style={{ borderRadius: borderRadius }}
            className={index === currentIndex ? css.active : css.inactive}
          />
        ))}
      </div>
      <div className={css.dots}>
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? css.activeDot : css.dot}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;