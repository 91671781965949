export const statusText: { [key: number]: string } = {
    0: 'не оплачен',
    1: 'принят',
    2: 'собираем',
    3: 'в пути',
    4: 'уже у вас',
    5: 'отменен'
}

export const statusDesc: { [key: number]: string } = {
    0: 'пожалуйста, оплатите заказ',
    1: 'мы приняли заказ, спасибо',
    2: 'уже собираем, скоро отправим',
    3: 'уже едет к вам',
    4: 'доставили курьерской службой',
    5: 'вы отменили заказ'
}

export const providers: { [key: string]: string } = {
    'cdek': 'СДЕК',
    'boxberry': 'Boxberry',
    'yandex': 'Яндекс',
    'post': 'Почта России',
    'shop': 'Магазин'
}