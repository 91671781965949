import { FC } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import { toast } from 'sonner';
import useUserData from "@hooks/useUserData";

interface CartProduct {
    id: number;
    prodID?: number;
    title: string; 
    desc?: any;
    price: number;
    image: string;
    count?: number;
    buttons?: boolean;
    width?: string;
}

const CartProduct: FC<CartProduct> = ({ id, prodID, title, desc, price, image, count, buttons, width }) => {
    const token = localStorage.getItem('authToken');
    const { refreshCartData } = useUserData();
    const navigate = useNavigate();

    const goProduct = () => {
        navigate(`/product/${prodID}`);
    };

    const manageProduct = (type: number, id: number) => {
        if (!token) {
            const existingCart = JSON.parse(localStorage.getItem('cart') || '[]');
            const itemIndex = existingCart.findIndex((item: any) => item.id === id);

            if (itemIndex !== -1) {
                if (type === 0) {
                    existingCart[itemIndex].count += 1;
                } else {
                    existingCart[itemIndex].count -= 1;
                    if (existingCart[itemIndex].count <= 0) {
                        existingCart.splice(itemIndex, 1);
                    }
                }
                localStorage.setItem('cart', JSON.stringify(existingCart));
                toast.success(type === 0 ? 'Добавили продукт' : 'Удалили продукт');
                refreshCartData();
            }
            return;
        }

        axios.post(`${import.meta.env.VITE_API_URL}/user/cart/${type === 0 ? 'add' : 'delete'}`, { prodID: id }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
          }).then(_ => {
            toast.success(type === 0 ? 'Добавили продукт' : 'Удалили продукт');
            refreshCartData();
          }).catch(error => {
            console.log(error)
        });
    }

    const formatNumber = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={css.cartProduct}>
            <div className={css.leftSide}>
                <div className={css.img}>
                    <div className={css.image} style={{ backgroundImage: `url(${image})` }} />
                    {count && count > 1 && <div className={css.count}>{count}</div> }
                    {buttons &&
                        <div className={css.manage}>
                            <div className={css.add} onClick={() => manageProduct(0, id)}><i className="fi fi-rr-plus"></i></div>
                            <div className={css.delete} onClick={() => manageProduct(1, id)}><i className="fi fi-rr-minus"></i></div>
                        </div>
                    }
                </div>

                <div className={css.textInfo} onClick={goProduct}>
                    <h2 style={{ width: width}}>{title}</h2>
                    <a>{desc}</a>
                </div>
            </div>
            <a className={css.price}>{formatNumber(price)} р.</a>
        </div>
    );
};

export default CartProduct;