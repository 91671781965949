import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import * as Types from '../types';
import axios from 'axios';

interface UserDataContextType {
    userData: any;
    isLoading: boolean;
    cartData: Types.CartData[];
    favoriteData: Types.Favorites[];
    siteData: any;
    refreshUserData: () => Promise<void>;
    refreshCartData: () => Promise<void>;
    refreshFavData: () => Promise<void>;
    fetchSiteData: () => Promise<void>;
    logout: () => void;
}

const UserDataContext = createContext<UserDataContextType | null>(null);

export const UserDataProvider = ({ children }: any) => {
    const [token, setToken] = useState(localStorage.getItem('authToken'));
    const [siteData, setSiteData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [favData, setFavData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchUserData = useCallback(async () => {
        if (!token) return;

        try {
          const response = await axios.get(`${import.meta.env.VITE_API_URL}/user`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
          });
          setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
            localStorage.removeItem('authToken');
            setToken(null);
            setUserData([]);
        }
    }, [token]);

    const fetchCartData = useCallback(async () => {
        if (!token) { 
            const existingCart = JSON.parse(localStorage.getItem('cart') || '[]');
            setCartData(existingCart);
            return;
        };

        try {
            const response = await axios.get(`${import.meta.env.VITE_API_URL}/user/cart`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            setCartData(response.data);
        } catch (error) {
            console.error('Error fetching cart data:', error);
            setCartData([]);
        }
    }, [token]);

    const fetchFavData = useCallback(async () => {
        if (!token) return;

        try {
            const response = await axios.get(`${import.meta.env.VITE_API_URL}/user/favorites`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            setFavData(response.data);
        } catch (error) {
            console.error('Error fetching favorite data:', error);
            setFavData([]);
        }
    }, [token]);

    const fetchSiteData = useCallback(async () => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_API_URL}/site`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setSiteData(response.data);
        } catch (error) {
            console.error('Error fetching site data:', error);
            setSiteData([]);
        }
    }, []);

    const fetchAllData = useCallback(async () => {
        setIsLoading(true);
        await Promise.all([fetchUserData(), fetchFavData()]);
        setIsLoading(false);
    }, [fetchUserData, fetchFavData]);

    useEffect(() => {
        fetchSiteData();
        fetchCartData();
        if (token) fetchAllData();
    }, [fetchAllData, token]);

    const logout = useCallback(() => {
        localStorage.removeItem('authToken');
        setToken(null);
        setUserData([]);
        setCartData([]);
        setFavData([]);
        window.location.replace("/");
    }, []);

    return (
        <UserDataContext.Provider
            value={{
              userData: userData,
              isLoading: isLoading,
              cartData: cartData,
              siteData: siteData,
              favoriteData: favData,
              refreshUserData: fetchUserData,
              refreshCartData: fetchCartData,
              refreshFavData: fetchFavData,
              fetchSiteData: fetchSiteData,
              logout,
            }}
        >
            {children}
        </UserDataContext.Provider>
    );
};

export default function useUserData() {
    const context = useContext(UserDataContext);
    if (!context) {
        throw new Error('useUserData must be used within a UserDataProvider');
    }
    return context;
}